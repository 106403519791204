<template lang="pug">
v-app
  Header(v-if="$auth.isAuth() && $route.meta.access !== 'public'")
  div(v-if="$auth.isAuth() && $route.meta.access !== 'public' && counters.unpaid_statements").header-notification
    span(@click="$router.push('/statements/unpaid')").pointer {{ $t('availableRequirePaymentApplications') }}
  v-main(v-if="$auth.isAuth() && $route.meta.access !== 'public'")
    v-container(:class="{ 'px-4': mobileBreakpoint }").mt-15
      router-view
  v-main(v-else)
    div(:class="{ 'px-4': mobileBreakpoint }").d-flex.justify-center.align-center.h-100
      router-view
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header: () => import('@/components/Header')
  },
  computed: {
    ...mapState({
      counters: state => state.auth.counters
    }),
    mobileBreakpoint () { return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) }
  },
  watch: {
    '$route.name': {
      handler (value) {
        if (value && this.$recaptchaInstance) {
          if (value === 'registration') this.$recaptchaInstance.showBadge()
          else this.$recaptchaInstance.hideBadge()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.$recaptchaLoaded().then(() => {
      if (this.$route.name !== 'registration') this.$recaptchaInstance.hideBadge()
    })

    this.getCountries()
    this.getSexes()
    if (this.$auth.isAuth()) {
      this.getDirectories()
      this.getProfileInformation()
      this.getProfilePhotos()
      this.getCountDocuments()
      this.getDeliveryCities()
    }
  },
  methods: {
    ...mapActions(['getDirectories', 'getProfileInformation', 'getCountDocuments', 'getCountries', 'getDeliveryCities',
      'getProfilePhotos', 'getSexes'])
  }
}
</script>
