import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import { downloadFile, getQueryString } from '@/configs/functions'
import notify from '@ananasbear/notify'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getEtiDocuments ({ commit }, params) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.certificateETI() + getQueryString(params))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'eti', data: response.data })
    return response
  },
  async getETIDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.certificateETI(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'etiSingleInfo', data: response.data })
    return response
  },
  async downloadCertificateETI ({ commit }, { id, number_document }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.etiDownload(id), { responseType: 'blob' })
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      downloadFile(URL.createObjectURL(response.data), `№${number_document}`)
      notify.success('notify.documentDownloadedSuccessfully')
    }
    return response
  },
  async sendEmailCertificateETI ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.etiSendEmail(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) notify.success('sendDocumentToEmail')
    return response
  }
}
