import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getMedicalDocuments ({ commit }, params) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.medical() + getQueryString(params))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'medical', data: response.data })
    return response
  },
  async getMedicalDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.medical(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'medicalSingleInfo', data: response.data })
    return response
  }
}
