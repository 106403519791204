import Vue from 'vue'
import Router from 'vue-router'
import authorization from '@/plugins/auth'
import documentsRoutes from '@/router/documents.routes'
import statementRoutes from './statement.routes'

Vue.use(Router)

export const routes = [
  ...statementRoutes,
  ...documentsRoutes,
  {
    path: '*',
    component: () => import('@/views/NotFound'),
    meta: {
      access: 'public'
    }

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
    meta: {
      access: 'public'
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/Registration/Registration'),
    meta: {
      access: 'public'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile/Profile'),
    meta: {
      name: 'sailorProfile'
    }
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: () => import('@/views/Profile/ChangeEmail'),
    meta: {
      name: 'changeEmail'
    }
  },
  {
    path: '/change-email',
    name: 'change-email',
    component: () => import('@/views/Profile/ChangeEmail'),
    meta: {
      name: 'changeEmail'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings'),
    meta: {
      name: 'settings'
    }
  },
  {
    path: '/settings/active-sessions',
    name: 'settings-active-sessions',
    component: () => import('@/views/ActiveSessions'),
    meta: {
      name: 'activeSessions'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/Services'),
    meta: {
      name: 'services'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/Notifications/List')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PrivacyPolicy'),
    meta: {
      access: 'public'
    }
  },
  {
    path: '/personal-data-processing',
    name: 'personal-data-processing',
    component: () => import('@/views/PersonalDataProcessing'),
    meta: {
      access: 'public'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (authorization.isAuth()) {
    if (['login', 'registration'].includes(to.name)) next('/')
    else next()
  }
  else if (to.meta.access === 'public') next()
  else next('/login')
})

export default router
