
const SET_DATA = (context, data) => {
  context[data.store][data.state] = data.data
}

const ADD_DATA = (context, data) => {
  context[data.store][data.state].push(data.data)
}

const UPDATE_DATA = (context, data) => {
  context[data.store][data.state] = context[data.store][data.state].map(i => {
    if (Number(i.id) === Number(data.data.id)) {
      return data.data
    }
    return i
  })
}

const UPDATE_DATA_RESULTS = (context, data) => {
  if (!context[data.store][data.state]) return false
  context[data.store][data.state].results = context[data.store][data.state].results.map(i => {
    if (Number(i.id) === Number(data.data.id)) {
      return data.data
    }
    return i
  })
}

const DELETE_DATA = (context, data) => {
  context[data.store][data.state] = context[data.store][data.state].filter(i => i.id !== data.data)
}

const DELETE_DATA_RESULTS = (context, data) => {
  if (!context[data.store][data.state]) return false
  context[data.store][data.state].results = context[data.store][data.state].results.filter(i => i.id !== data.data)
}

export default {
  SET_DATA, ADD_DATA, UPDATE_DATA, DELETE_DATA, UPDATE_DATA_RESULTS, DELETE_DATA_RESULTS
}

