import { directories, singleDirectories, delivery } from '@/configs/endpoints'
import api from '@/plugins/api'
import { getQueryString } from '@/configs/functions'

const store = 'directory'
export default {
  state: () => ({
    isLoadDirectories: true,
    country: [],
    region: [],
    port: [],
    branchOffice: [],
    sex: [],
    rank: [],
    position: [],
    direction: [],
    typeRank: [],
    limitation: [],
    levelQualification: [],
    functionsForPosition: [],
    decision: [],
    typeDocument: [],
    typeDocumentEducation: [],
    reason: [],
    eti: [],
    etiCourse: [],
    medicalInstitution: [],
    doctorInMedical: [],
    positionForMedical: [],
    limitationForMedical: [],
    educationalInstitution: [],
    speciality: [],
    specialization: [],
    extentEducation: [],
    educationForm: [],
    faculty: [],
    positionForExperience: [],
    typeVessel: [],
    modeOfNavigation: [],
    typeGeu: [],
    responsibilityWorkBook: [],
    responsibility: [],
    statusDocument: [],
    typeStatusDocument: [],
    sailorPassportChoice: [],
    deliveryCity: [],
    deliveryWarehouse: [],
    deliveryStreet: [],
    typeDelivery: [
      // eslint-disable-next-line vue/max-len
      { id: 3, name_ukr: 'Самовіз з Моррічсервісу', name_eng: 'Pickup from Morrichservice', delivery_service: 'morrichservice' },
      { id: 2, name_ukr: 'Нова Пошта (курʼєр)', name_eng: 'Nova Poshta (courier)', delivery_service: 'novaposhta' },
      { id: 1, name_ukr: 'Нова Пошта (самовивіз)', name_eng: 'Nova Poshta (pickup)', delivery_service: 'novaposhta' }
    ],
    processingType: [
      { id: 'itcs', name_ukr: 'Оформити в ІПДМ', name_eng: 'Issue in ITCS' },
      { id: 'morrichservice', name_ukr: 'Оформити в МРС', name_eng: 'Issue in MRS' }
    ],
    morrichserviceOffice: [],
    receivingTypes: [
      { id: 1, 'name_ukr': 'ПОМ потрібно за 20 днів', 'name_eng': 'Needed in 20 days' },
      { id: 2, 'name_ukr': 'ПОМ потрібно за 7 днів', 'name_eng': 'Sailor`s passport needed in 7 days' },
      { id: 3, 'name_ukr': 'Подовження ПОМ за 20 днів', 'name_eng': 'Sailor`s passport renewal in 20 days' },
      { id: 4, 'name_ukr': 'Подовження ПОМ за 7 днів', 'name_eng': 'Sailor`s passport renewal in 7 days' },
    ],
    sqcTypesList: [
      {
        id: 0,
        name_ukr: 'ДКК іспит',
        name_eng: 'SQC exam',
        is_continue: null,
        ces_exam: false
      },
      {
        id: 1,
        name_ukr: 'ДКК іспит (присвоєння звання)',
        name_eng: 'SQC exam (assignment of rank)',
        is_continue: 0,
        ces_exam: false
      },
      {
        id: 2,
        name_ukr: 'ДКК іспит (підтвердження звання)',
        name_eng: 'SQC exam (rank confirmation)',
        is_continue: 1,
        ces_exam: false
      },
      {
        id: 3,
        name_ukr: 'ДКК іспит (підтвердження з присвоєнням нової посади)',
        name_eng: 'SQC exam (confirmation with the assignment of a new position)',
        is_continue: 2,
        ces_exam: false
      },
      {
        id: 4,
        name_ukr: 'ДКК стаж',
        name_eng: 'SQC seniority',
        is_continue: 3,
        ces_exam: false
      },
      {
        id: 5,
        name_ukr: 'Іспит CES 6.0',
        name_eng: 'Exam CES 6.0',
        is_continue: null,
        ces_exam: true
      },
      {
        id: 6,
        name_ukr: 'Іспит CES 6.0 - ДКК іспит (присвоєння звання)',
        name_eng: 'Exam CES 6.0 - SQC exam (assignment of rank)',
        is_continue: 0,
        ces_exam: true
      },
      {
        id: 7,
        name_ukr: 'Іспит CES 6.0 - ДКК іспит (підтвердження звання)',
        name_eng: 'Exam CES 6.0 - SQC exam (rank confirmation)',
        is_continue: 1,
        ces_exam: true
      },
      {
        id: 8,
        name_ukr: 'Іспит CES 6.0 - ДКК іспит (підтвердження з присвоєнням нової посади)',
        name_eng: 'Exam CES 6.0 - SQC exam (confirmation with the assignment of a new position)',
        is_continue: 2,
        ces_exam: true
      }
    ],
    typeDocumentsOnVerification: [
      {
        id: 1,
        name_ukr: 'Посвідчення особи моряка',
        name_eng: 'Sailor passport',
        link: '/documents/sailor-passport/create',
        detailLink: 'documents-sailor-passport-info',
        createLink: 'documents-sailor-passport-create',
        content_type: 'sailorpassport',
        is_used_for_verification: true
      },
      {
        id: 2,
        name_ukr: 'Громадянський паспорт',
        name_eng: 'Civil passport',
        link: '/documents/civil-passport/create',
        detailLink: 'documents-civil-passport-info',
        createLink: 'documents-civil-passport-create',
        content_type: 'passport',
        is_used_for_verification: true
      },
      {
        id: 3,
        name_ukr: 'Закордонний паспорт',
        name_eng: 'Foreign passport',
        link: '/documents/foreign-passport/create',
        detailLink: 'documents-foreign-passport-info',
        createLink: 'documents-foreign-passport-create',
        content_type: 'foreignpassport',
        is_used_for_verification: true
      },
      {
        id: 4,
        name_ukr: 'Освітній документ',
        name_eng: 'Education document',
        link: '/documents/education/create',
        detailLink: 'documents-education-info',
        createLink: 'documents-education-create',
        content_type: 'education',
        is_used_for_verification: true
      },
      {
        id: 5,
        name_ukr: 'Кваліфікаційний документ',
        name_eng: 'Qualification document',
        link: '/documents/qualification/create',
        detailLink: 'documents-qualification-info',
        createLink: 'documents-qualification-create',
        content_type: 'qualificationdocument',
        is_used_for_verification: true
      },
      {
        id: 6,
        name_ukr: 'Підтвердження диплому',
        name_eng: 'Diploma proof',
        link: '/documents/diploma-proof/create',
        detailLink: 'documents-diploma-proof-info',
        createLink: 'documents-diploma-proof-create',
        content_type: 'proofofworkdiploma',
        is_used_for_verification: true
      },
      {
        id: 9,
        name_ukr: 'Послужна книжка моряка',
        name_eng: 'Service record book',
        link: '/documents/experience/service-record/create',
        detailLink: 'documents-service-record-info',
        createLink: 'documents-service-record-create',
        content_type: 'servicerecord',
        is_used_for_verification: true
      },
      {
        id: 11,
        name_ukr: 'Медична довідка',
        name_eng: 'Medical certificate',
        link: '/documents/medical/create',
        detailLink: 'documents-medical-info',
        createLink: 'documents-medical-create',
        content_type: 'medicalcertificate',
        is_used_for_verification: true
      },
      {
        id: 12,
        name_ukr: 'Запис у ПКМ',
        name_eng: 'Record book entry',
        link: '/documents/experience/service-record/:serviceRecordId/entry/create',
        detailLink: 'documents-service-record-entry-info',
        createLink: 'documents-service-record-entry-create',
        content_type: 'lineinservicerecord',
        is_used_for_verification: true
      },
      {
        id: 13,
        name_ukr: 'Довідка про стаж плавання',
        name_eng: 'Experience reference',
        link: '/documents/experience/entry/create',
        detailLink: 'documents-entry-info',
        createLink: 'documents-entry-create',
        content_type: 'lineinservicerecord',
        is_used_for_verification: true
      },
      {
        id: 100, // Experience reference and employment book has same id's, it will be changed later
        name_ukr: 'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо',
        name_eng: 'Employment book, certificate of professional experience, repair, practice, etc',
        link: '/documents/experience/employment-book/create',
        detailLink: 'documents-entry-info',
        createLink: 'documents-employment-book-create',
        content_type: 'lineinservicerecord',
        is_used_for_verification: true
      },
      {
        id: 101,
        name_ukr: 'Заява ДКК',
        name_eng: 'SQC statement',
        link: '/statements/sqc/form',
        detailLink: 'sqc-statements-info',
        createLink: 'sqc-statements-form',
        content_type: 'statementsqc',
        is_used_for_verification: false
      },
      {
        id: 102,
        name_ukr: 'Заява ДПВ',
        name_eng: 'Qualification statement',
        link: '/statements/qualification/form',
        detailLink: 'qualification-statements-info',
        createLink: 'qualification-statements-form',
        content_type: 'statementqualification',
        is_used_for_verification: false
      },
      {
        id: 103,
        name_ukr: 'Заява ПОМ',
        name_eng: 'Sailor passport statement',
        link: '/statements/sailor-passport/form',
        detailLink: 'sailor-passport-statements-info',
        createLink: 'sailor-passport-statements-form',
        content_type: 'statementsailorpassport',
        is_used_for_verification: false
      },
      {
        id: 104,
        name_ukr: 'Заява ПКМ',
        name_eng: 'Service record book statement',
        link: '/statements/service-record/form',
        detailLink: 'service-record-statements-info',
        createLink: 'service-record-statements-form',
        content_type: 'statementservicerecord',
        is_used_for_verification: false
      },
      {
        id: 105,
        name_ukr: 'Заява на уточнення даних',
        name_eng: 'A statement to clarify data',
        link: '/statements/clarification-data/form',
        detailLink: 'clarification-data-statements-info',
        createLink: 'clarification-data-statements-form',
        content_type: 'statementclarification',
        is_used_for_verification: false
      }
    ],
    taxNumberAvailabilityList: [
      {
        value: true,
        name_ukr: 'Індивідуальний Податковий Номер наявний',
        name_eng: 'Individual Tax Number available'
      },
      {
        value: false,
        name_ukr: 'Індивідуальний Податковий Номер відсутній',
        name_eng: 'There is no Individual Tax Number'
      }
    ],
    refrigeratingPlantsList: [
      { value: null, name_ukr: 'Н/Д', name_eng: 'N/A' },
      { value: 1, name_ukr: '1', name_eng: '1' },
      { value: 2, name_ukr: '2', name_eng: '2' }
    ],
    availabilityList: [
      { value: true, name_ukr: 'Присутня', name_eng: 'Present' },
      { value: false, name_ukr: 'Відсутня', name_eng: 'Absent' }
    ],
    shortAnswersList: [
      { value: true, name_ukr: 'Так', name_eng: 'Yes' },
      { value: false, name_ukr: 'Ні', name_eng: 'No' }
    ],
    languagesList: [
      { name: 'UA', value: 'uk' },
      { name: 'EN', value: 'en' }
    ]
  }),
  actions: {
    getDirectories ({ commit }) {
      for (const endpoint of directories) {
        api.get(endpoint.endpoint).then(response => {
          if (response?.code === 200) commit('SET_DATA', { store, state: endpoint.state, data: response.data })
        })
        if (endpoint.state === 'morrichserviceOffice') commit('SET_DATA', { store, state: 'isLoadDirectories', data: false })
      }
    },

    async getCountries ({ commit }) {
      const response = await api.get(singleDirectories.country)
      if (response?.code === 200) commit('SET_DATA', { store, state: 'country', data: response.data })
      return response
    },

    async getSexes ({ commit }) {
      const response = await api.get(singleDirectories.sex)
      if (response?.code === 200) commit('SET_DATA', { store, state: 'sex', data: response.data })
      return response
    },

    async getRegions (context, params) {
      return await api.get(singleDirectories.region + getQueryString(params))
    },

    async getCities (context, params) {
      return await api.get(singleDirectories.city + getQueryString(params))
    },

    async getDeliveryCities ({ commit }) {
      const response = await api.get(delivery.city)
      if (response?.code === 200) commit('SET_DATA', { store, state: 'deliveryCity', data: response.data })
      return response
    },

    async getDeliveryWarehouses ({ commit }, id) {
      const response = await api.get(delivery.warehouse(id))
      if (response?.code === 200) commit('SET_DATA', { store, state: 'deliveryWarehouse', data: response.data })
      return response
    },

    async getDeliveryStreets ({ commit }, id) {
      const response = await api.get(delivery.street(id))
      if (response?.code === 200) commit('SET_DATA', { store, state: 'deliveryStreet', data: response.data })
      return response
    }
  },
  getters: {
    directoryById: state => (directory, id) => state[directory].find(item => item.id === id),
    directoryByContentType: state => (directory, contentType) => {
      return state[directory].find(item => item.content_type === contentType)
    },
    directoryByValue: state => (directory, value) => state[directory].find(item => item.value === value),
    positionsByRank: state => id => state.position.filter(item => item.rank === +id),
    positionsByID: (state, getters) => (positionsList, labelName) => { // Get joined positions list of id's by labelName
      if (state.position.length && positionsList?.length) {
        return positionsList.map(item => getters.directoryById('position', item)[labelName]).join(', ')
      } else return '-'
    },
    sqcStatementType: state => (isContinue, cesExam) => {
      return state.sqcTypesList.find(item => item.is_continue === isContinue && item.ces_exam === cesExam)
    },
    doctorByMedicalInstitution: state => id => state.doctorInMedical.filter(item => item.medical_institution === +id),
  }
}
