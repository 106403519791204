import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getProtocolSQCDocuments ({ commit }, params) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.protocolSQC() + getQueryString(params))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'protocolSQC', data: response.data })
    return response
  },
  async getSQCDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.protocolSQC(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'protocolSQCSingleInfo', data: response.data })
    }
    return response
  },
  async getValidProtocolSQC ({ commit }, body) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.post(endpoint.validProtocolSQC, { body })
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if ([200, 201].includes(response?.code)) commit('SET_DATA', { store, state: 'validProtocolSQC', data: response.data })
    return response
  },
  async sendProtocolSqcResults ({ commit }, { id, body }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.post(endpoint.resultsProtocolSQC(id), { body })
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    return response
  }
}
