import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getSailorPassports ({ commit }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.sailorPassport())
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'sailorPassport', data: response.data.results })
    }
    return response
  },
  async getSailorPassport ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.sailorPassport(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'sailorPassportSingleInfo', data: response.data })
    }
    return response
  }
}
