<template lang="pug">
  div
    v-stepper(v-model="activeStep").custom-stepper
      div(v-if="showTitle")
        slot(name="title")
      div.auth-stepper.mt-8.mb-8
        div.auth-stepper-item(v-for="(step, i) of steps" :key='i' :class="{ 'active': i <= activeStep }")
      v-stepper-items
        v-stepper-content(v-for="(step, i) of steps" :key='i' :step="`${ i }`").pa-0
          slot(:name="`step-${i + 1}`")
</template>

<script>

export default {
  props: {
    active: { type: Number, default: 1 },
    steps: { type: Number, default: 2 }
  },
  computed: {
    showTitle: context => context.$slots.title,
    activeStep () { return this.active - 1 }
  }
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/modules/stepper.sass'
</style>
