<template lang="pug">
  div
    slot(name="breadcrumbs")
    div.d-flex.align-center.justify-space-between.mb-6.pointer
      div(@click="goBack").d-flex.align-center
        img(src="@/assets/icons/arrow-left.svg" width="6" height="12" alt="arrow-left").mr-2
        div.fs-16.fw-500 {{ $t('back') }}
    div.d-flex.justify-space-between.mb-6
      slot(name="title")
    slot(name="page")
</template>

<script>
export default {
  name: 'PageInfo',
  props: {
    routeBackName: { type: String, default: 'documents-dashboard' }
  },
  methods: {
    goBack () {
      if (this.$router.currentRoute.path === this.$router.history._startLocation) {
        this.$router.push({ name: this.routeBackName })
      } else this.$router.go(-1)
    }
  }
}
</script>
