import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import { downloadFile, getQueryString } from '@/configs/functions'
import notify from '@ananasbear/notify'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getQualificationDocuments ({ commit }, params) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.qualification() + getQueryString(params))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      response.data.results = response.data.results.reduce((acc, cur) => {
        acc.push(cur)
        if (cur.proofs?.length) acc.push(...cur.proofs)
        return acc
      }, [])
      response.data.results.map(item => item.detailLink = item.type_document === 16 ? 'diploma-proof/' : 'qualification/')
      commit('SET_DATA', { store, state: 'qualification', data: response.data })
    }
    return response
  },
  async getQualificationDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.qualification(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'qualificationSingleInfo', data: response.data })
    }
    return response
  },
  async getProofDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.proof(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'proofsForDiplomaSingleInfo', data: response.data })
    }
    return response
  },
  async getDiplomasForApply ({ commit }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.diplomasForApply)
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'diplomasForApply', data: response.data })
    return response
  },
  async downloadQualificationDocument ({ commit }, { id, number }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.qualificationDownload(id), { responseType: 'blob' })
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      downloadFile(URL.createObjectURL(response.data), `№${number}`)
      notify.success('notify.documentDownloadedSuccessfully')
    }
    return response
  },
  async sendEmailQualificationDocument ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.qualificationSendEmail(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) notify.success('sendDocumentToEmail')
    return response
  },
  async downloadProofDocument ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.proofDownload(id), { responseType: 'blob' })
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) downloadFile(URL.createObjectURL(response.data))
    return response
  },
  async sendEmailProofDocument ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.proofSendEmail(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) notify.success('sendDocumentToEmail')
    return response
  }
}
