import { i18n } from '@/plugins/vue-i18n.js'
import '@/assets/sass/modules/notifications.sass'
import SuccessIcon from '@/assets/icons/check-circle-green.svg'
import WarningIcon from '@/assets/icons/alert-triangle-yellow.svg'
import ErrorIcon from '@/assets/icons/x-circle-red.svg'
import InfoIcon from '@/assets/icons/info-blue.svg'

export default {
  global: {
    close: true,
    pauseOnHover: true,
    timeout: 3000,
    progressBar: false,
    layout: 2,
    position: 'topRight'
  },

  info: {
    iconUrl: InfoIcon,
    title: i18n.t('notify.info')

  },

  success: {
    iconUrl: SuccessIcon,
    title: i18n.t('notify.success')
  },

  warning: {
    iconUrl: WarningIcon,
    title: i18n.t('notify.warning')
  },

  error: {
    iconUrl: ErrorIcon,
    title: i18n.t('notify.error')
  }
}
