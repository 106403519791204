import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'

import Directory from './modules/directory'
import Documents from '@/store/modules/documents'
import Auth from '@/store/modules/auth'
import Statement from '@/store/modules/statements'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    directory: Directory,
    documents: Documents,
    auth: Auth,
    statement: Statement
  },
  mutations
})

export default store
