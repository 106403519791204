export default {
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...'
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.'
      },
      sortBy: 'Sort by'
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year'
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} more'
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}'
      }
    }
  },
  notify: {
    validation: {
      required: 'Required field',
      email: 'Invalid email format',
      maxLength: 'Maximum length exceeded',
      minLength: 'Minimum length exceeded',
      maxValue: 'Maximum value exceeded',
      minValue: 'Minimum value exceeded',
      alphaUA: 'Use only Ukrainian',
      alphaEN: 'Use only Latin letters',
      checked: 'Fulfillment of conditions is mandatory'
    },
    success: 'Done successfully',
    warning: 'Pay attention',
    error: 'Execution error',
    info: 'Message',
    noData: 'There are no data',
    invalidUploadedFilesAmount: 'Maximum number of files: {amount}',
    invalidUploadedFilesType: 'Unable to download this file type',
    statementCreatedSuccessfully: 'The application has been created',
    statementCanceledSuccessfully: 'The application has been canceled',
    statementPaidSuccessfully: 'The application has been paid',
    emailChangedSuccessfully: 'The email address has been changed',
    phoneChangedSuccessfully: 'The phone number has been changed',
    uploadedProfilePhotoSuccessfully: 'Profile photo sent for verification',
    documentCreatedSuccessfully: 'The document has been sent for verification',
    documentDownloadedSuccessfully: 'The document has been downloaded to the device',
    sendExamResultsSuccessfully: 'The exam results have been sent to your email',
    notFoundDocuments: 'No documents were found',
    errors: {
      'Proof is not found': 'Confirmation of a diploma is not enough to successfully create a document!',
      // eslint-disable-next-line vue/max-len
      'No valid education documents has been found': 'A valid education document is not enough to successfully create a document!',
      'Sailor does not have foreign passport': 'A foreign passport is not enough to successfully create a document!',
      'Sailor does not have document with citizenship': 'A civil passport is not enough to successfully create a document!',
      'Photo for document does not exists': 'A verified photo of a sailor is not enough to successfully create a document!',
      'Statement replacement already exists': 'The application for replacement of the qualification document already exists',
      'Statement cannot be created': 'The service is temporarily down',
      'Statement with this rank/positions exists': 'An application for this title/position already exists',
      'Statement exist': 'An application already exists',
      'Statement exists': 'An application already exists',
      'Document exists': 'A document already exists',
      'User already registered': 'The user is already registered',
      'User not found': 'Account not found',
      'Try later': 'Try later',
      'Email is used': 'The email address is already in use',
      'Code is invalid': 'Code is invalid',
      'Sailor does not have enough confirmed experience': 'Insufficient proven experience',
      'Qualification document with this statement exists': 'Qualification document with this statement exists'
    }
  },
  btn: {
    pay: 'Pay',
    reject: 'Reject',
    cancel: 'Cancel',
    leave: 'Leave',
    back: 'Previous step',
    continue: 'Continue',
    register: 'Register',
    goAuth: 'Go to authorization',
    upload: 'Upload',
    later: 'Later',
    understand: 'Understand',
    goHome: 'Home',
    clear: 'Clear',
    delete: 'Delete',
    deleteAll: 'Delete all',
    read: 'Read',
    readAll: 'Read all'
  },
  registration: 'Registration',
  authorization: 'Authorization',
  enterAuthPhoneNumber: 'Enter your phone number to log in',
  sendVerificationCode: 'We have sent an SMS with a verification code to your {phone} number',
  authWithPhoneNumber: 'Log in by phone number',
  authWithBankID: 'Log in via Bank ID',
  noAccount: 'Don\'t have an account?',
  existAccount: 'Already have an account?',
  privacyPolicyInfo: {
    iConsent: 'I provide',
    processingOfPersonalData: 'Consent to the processing of personal data',
    confirmFamiliarWithContent: 'and I confirm that I am familiar with the content',
    processingOfPersonalNotice: 'Privacy policies.'
  },
  experienceDocs: {
    'ПКМ': 'SSRB',
    'Довідка про стаж плавання': 'Certificate of swimming experience',
    // eslint-disable-next-line vue/max-len
    'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо': 'Employment book, certificate of professional experience, repair, practice, etc',
    'Запис ПКМ': 'SSRB entry'
  },
  enterSystem: 'Sign in',
  enterPhoneNumber: 'Enter a phone number',
  enterCode: 'Enter the received code',
  notReceivedCode: 'Didn\'t get the code?',
  resendCode: 'Resend',
  enterRequiredData: 'Enter required information',
  enterPassportData: 'Enter the data from your passport',
  enterTaxNumberData: 'Enter your personal identification number',
  lastName: 'Last name',
  firstName: 'First name',
  middleName: 'Middle name',
  lastNameUkr: 'Last name (in Ukrainian)',
  firstNameUkr: 'First name (in Ukrainian)',
  middleNameUkr: 'Middle name (in Ukrainian)',
  lastNameEng: 'Surname (in English)',
  firstNameEng: 'Name (in English)',
  dateIssue: 'Date of issue',
  dateTermination: 'Expiry date',
  dateStudyTermination: 'Date of termination of studies',
  dateStudyStart: 'Start date of study',
  dateRenewal: 'The validity period of the certificate has been extended to',
  dateCreate: 'Creating date',
  email: 'E-mail',
  phoneNumber: 'Phone number',
  serialPassport: 'Passport series and number',
  id: 'ID',
  oldPattern: 'Old model',
  idPassport: 'ID - card',
  taxNumber: 'Individual tax number',
  taxNumberAvailability: 'ITX availability',
  uploadPhoto: 'Upload a photo',
  uploadPassportPhotos: 'Download the required passport pages',
  uploadIdPassportPhotos: 'Upload two photos of ID - passport - front and back',
  uploadAdditionalPhotos: 'Download additional photos',
  uploadTaxNumberPhoto: 'Upload a photo of your tax number',
  dataInProcess: 'Your data is being processed',
  // eslint-disable-next-line vue/max-len
  verificationResults: 'The results of the verification will be provided to you in an SMS message immediately after processing by the operator.',
  support: 'Support service',
  supportDescription: 'Contact our support service in a convenient way',
  explanation: 'Explanation',
  // eslint-disable-next-line vue/max-len
  uploadPhotoExplanation: 'Upload a photo of the first, second and third, fourth and fifth, sixth and seventh pages of the passport.</br></br>Also attach a photo with the last record of registration of the month of residence',
  sailorProfile: 'Profile of a sailor',
  sailorId: 'Sailor ID',
  dateBirth: 'Date of birth',
  foreignPassport: 'Foreign passport',
  absentForeignPassport: 'Absent foreign passport',
  issuedBy: 'Issued by',
  issuedByCaptain: 'Issued by (Captain)',
  sailorPassport: 'Sailor\'s passport',
  absentSailorPassport: 'Absent sailor\'s passport',
  detailed: 'More details',
  etiCertificates: 'ETI Certificates',
  documentsOnVerification: 'Documents for verification',
  medicalDocuments: 'Medical documents',
  educationDocuments: 'Educational documents',
  qualificationDocuments: 'Qualification documents',
  experienceDocuments: 'Experience documents',
  protocolsSQC: 'SQC protocols',
  pom: 'SIC',
  settings: 'Settings',
  interfaceLanguage: 'Interface language',
  interfaceLanguageDescription: 'Choose the interface language that is convenient for you',
  // eslint-disable-next-line vue/max-len
  profilePageDescription: 'Your personal information is displayed in the sailor profile.',
  generalInformation: 'General information',
  additionalInformation: 'Additional information',
  workInformation: 'Work information',
  fullNameEng: 'Full name (english)',
  sex: 'Sex',
  citizenship: 'Citizenship',
  passportDateIssue: 'Passport date of issue',
  passportSerial: 'Passport series and number',
  issuedAuthority: 'Authority that issued',
  noImage: 'Image is missing',
  noPhoto: 'Photo is missing',
  rank: 'Rank',
  position: 'Position',
  editProfile: 'Edit profile',
  services: 'Services',
  paymentRequiredStatements: 'Applications that require payment',
  paymentRequiredStatementsDescription: 'Applications have been created that require payment',
  sqcStatement: 'SQC statement',
  // eslint-disable-next-line vue/max-len
  sqcStatementDescription: 'Electronic submission of an application for confirmation of qualification at the State Qualification Commission.',
  sqcStatements: 'SQC statements',
  sqcStatementCreate: 'Creating a SQC statement',
  addExistDocument: 'Add an existing document',
  addExistDocumentDescription: 'Electronic submission of an application for adding a document to the sailor\'s office.',
  qualificationStatement: 'Qualification statement',
  qualificationStatements: 'Qualification statements',
  qualificationStatementDescription: 'Electronic application submission to the seaman\'s diploma and passport department.',
  qualificationStatementCreate: 'Creating of qualification statement',
  foreignPassportVerificationStatements: 'Application for verification of a foreign passport',
  // eslint-disable-next-line vue/max-len
  foreignPassportVerificationStatementsDescription: 'Electronic submission of an application for verification of a seafarer\'s foreign passport.',
  serviceRecordBookStatement: 'Service record book statement',
  serviceRecordBookStatementDescription: 'Electronic submission of an application for obtaining a seafarer\'s record book.',
  serviceRecordBookStatements: 'Service record book statements',
  serviceRecordBookStatementCreate: 'Creation of a service record statement',
  serviceRecordBookStatementCreateDescription: 'Choose the place and method of delivery of your service record',
  civilPassportVerificationStatement: 'Application for confirmation of the passport of a citizen of Ukraine',
  // eslint-disable-next-line vue/max-len
  civilPassportVerificationStatementDescription: 'Electronic submission of an application for verification of a passport of a citizen of Ukraine.',
  sailorPassportStatement: 'Sailor passport statement',
  // eslint-disable-next-line vue/max-len
  sailorPassportStatementDescription: 'Electronic submission of an application for obtaining or extending the validity period of a seafarer\'s identity card.',
  sailorPassportStatements: 'Sailor passport statements',
  sailorPassportStatementCreate: 'Creating a sailor passport statement',
  // eslint-disable-next-line vue/max-len
  sailorPassportStatementCreateDescription: 'Electronic submission of an application for obtaining or extending the validity period of SP.',
  receiveSailorPassport: 'Get sailor passport',
  continueSailorPassport: 'Extend the validity period of sailor passport',
  chooseSailorPassport: 'Select Certificate №{number}',
  chooseSailorPassportDescription: 'Select the sailor passport whose validity period you wish to extend',
  clarificationDataStatement: 'Application for clarification of data',
  clarificationDataStatements: 'Applications for clarification of data',
  clarificationDataStatementCreate: 'Submission of an application for clarification of data',
  qualificationDocumentReplaceStatements: 'Applications for replacement of qualification document',
  qualificationDocumentReplaceStatement: 'Application for replacement of qualification document',
  createStatement: 'Create statement',
  myStatements: 'My statements',
  myDocuments: 'My documents',
  paid: 'Paid',
  unpaid: 'Not paid',
  sailorBook: 'SSRB',
  protocol: 'Protocol',
  verificationStatements: 'Verification statements',
  etiCertificate: 'ETI Certificate',
  eti: 'ETI',
  course: 'Course',
  status: 'Status',
  sign: 'Signature',
  withSign: 'Signed',
  withoutSign: 'Not signed',
  statementPayment: 'Statement payment',
  availableRequirePaymentApplications: 'You have applications that require payment',
  menu: 'Menu',
  statementInformation: 'Statement information',
  payment: 'Payment',
  blankPayment: 'Blank payment',
  examinationDate: 'Examination date',
  addReceipt: 'Add a receipt',
  cancelStatement: 'Cancel statement',
  // eslint-disable-next-line vue/max-len
  cancelStatementConfirmation: 'Are you sure you want to cancel application #{number}? Reverse operation will not be possible.',
  port: 'Port',
  choosePeriod: 'Select a period',
  receiptPeriod: 'Period of receipt',
  pleaseFillForm: 'Please fill out the form',
  checkInformation: 'Check your information to apply',
  protocolSQC: 'SQC protocol',
  protocolInfo: 'Protocol information',
  dateMeeting: 'Meeting date',
  positions: 'Positions',
  decision: 'Decision',
  diploma: 'Diploma',
  diplomaInfo: 'Diploma information',
  statementForChange: 'Replace statement',
  limitations: 'Limitations',
  proofInfo: 'Diploma\'s proof information',
  processingType: 'Processing type',
  deliveryType: 'Delivery type',
  branchOffice: 'Branch office',
  additionalFee: 'An additional fee may be charged for the provision of this service',
  deliveryCity: 'Delivery city',
  department: 'Department',
  street: 'Street',
  house: 'House',
  flat: 'Flat',
  statementType: 'Application type',
  yourMissingDocuments: 'You do not have enough required documents',
  noMissingDocuments: 'You have all the documents available',
  yourAvailableDocuments: 'Documents that are available',
  name: 'Name',
  number: 'Number',
  regulatoryRequirements: 'Regulatory requirements',
  notability: 'Notability',
  verified: 'Verified',
  notVerified: 'Not verified',
  notEnoughExperience: 'Experience is not enough',
  experience: 'Experience',
  notAllowedExperienceExam: 'Currently, it is not possible to create an application with the type "SQC experience"',
  qualificationReplaceStatements: 'Applications for the replacement of a qualification document',
  qualificationReplaceStatement: 'Application for the replacement of a qualification document',
  index: 'Index',
  region: 'Region',
  city: 'City',
  registrationPlace: 'Place of registration',
  residentPlaceMatch: 'The place of residence coincides with the place of registration',
  residentPlaceData: 'Data on place of residence',
  residentPlace: 'Resident place',
  portIsNotListed: 'The port is not listed',
  fullNumberDocument: 'Full document number',
  numberDocumentTillSymbol: 'Document number (before the first character "/")',
  agentFullName: 'Authorized person - full name (in Ukrainian)',
  agentLastNameName: 'Authorized person - last name (in Ukrainian)',
  agentFirstNameName: 'Authorized person - first name (in Ukrainian)',
  agentMiddleNameName: 'Authorized person - middle name (in Ukrainian)',
  agentFullNameEng: 'Authorized person - full name (in English)',
  agentLastNameEng: 'Authorized person - last name (in English)',
  agentFirstNameEng: 'Authorized person - first name (in English)',
  refrigeratingPlantsCount: 'The number of cooling units',
  grossCapacity: 'Gross capacity',
  shipOwner: 'Shipowner',
  swimmingArea: 'Sailing area',
  registrationPort: 'Port of registration',
  swimmingPort: 'Seaport',
  captainFullName: 'Captain - full name (in Ukrainian)',
  captainLastName: 'Captain - last name (in Ukrainian)',
  captainFirstName: 'Captain - first name (in Ukrainian)',
  captainMiddleName: 'Captain - middle name (in Ukrainian)',
  captainFullNameEng: 'Captain - full name (in English)',
  captainLastNameEng: 'Captain - last name (in English)',
  captainFirstNameEng: 'Captain - first name (in English)',
  shipPosition: 'Position on the ship',
  period: 'Period',
  totalDays: 'Total number of days',
  addResponsibility: 'Add a duty',
  captainInformation: 'Information about the captain',
  periodFrom: 'The period from',
  periodTo: 'The period to',
  workPlace: 'Place of work/practice',
  confirmEmail: 'Confirm your e-mail',
  pleaseVerifyEmail: 'Please confirm your e-mail, which was specified during registration, or enter a new one.',
  emailVerificationCode: 'A message with a verification code has been sent to your e-mail, {email}',
  acceptedForConsideration: 'Accepted for consideration',
  servicePaymentReceipt: 'Service payment receipt',
  documentInformation: 'Document information',
  additionalPhoneNumber: 'Additional phone number',
  photoOnVerification: 'The photo is under verification',
  enough: 'Enough',
  notEnough: 'Not enough',
  withQualificationReplacement: 'With the replacement of qualification documents',
  serviceBookEntry: 'Service book entry',
  yearEnd: 'Graduation year',
  sendDocumentToEmail: 'The document was sent to a verified e-mail',
  attention: 'Attention',
  qualificationReplacementConfirmation: 'Are you sure you want to apply for the replacement of Diploma document №{number}',
  authError: 'Authorization error',
  notVerifiedRegistrationStatement: 'Your application for registration has not yet been confirmed!',
  civilPassport: 'Civil passport',
  absentCivilPassport: 'Absent civil passport',
  addPassport: 'Add passport',
  createPassportStatement: 'Create an application for the creation of a passport.',
  addPhoto: 'Add photo',
  aspectRatioProfilePhoto: 'The photo should be close to the 3x4 format',
  notifications: 'Notifications',
  avatarRequiresFaceSize: 'Your face should be a close-up, with both eyes open.',
  // eslint-disable-next-line vue/max-len
  avatarRequiresFocus: 'The face should be in focus, with a neutral expression, without turning or tilting the head and neck.',
  avatarRequiresFaceLength: 'The length of the head should be 50% of the total length of the photo.',
  openChat: 'Open chat',
  documentNumber: 'Document number',
  link: 'Link',
  documentCreatedFrom: 'The document from which it was created',
  createdDocument: 'Created document',
  replacementPossibility: 'With the possibility of replacement',
  oops: 'Oops! A surprise happened!',
  pageNotExist: 'Sorry, no such page exists, or an error was made when entering the site address.',
  document: 'Document',
  requiredRecordBookPage: 'The title page of the Service book must be added to the entry in the Service book',
  activeSessions: 'Active sessions',
  manageYourActiveSessions: 'Manage your sessions in the system',
  terminateAllSessions: 'Close all except the current one',
  ipAddress: 'IP address: {address}',
  currentSession: 'Current session',
  activeSession: 'Active session',
  finishedSession: 'Session ended',
  finishSession: 'End session',
  online: 'Online',
  activeSessionsNumber: '{number} active sessions',
  sessionsNumber: '{number} sessions',
  uah: 'UAH',
  toBePaid: 'To be paid',
  noStatements: 'You currently have no applications.',
  noDocuments: 'You currently have no documents.',
  changeProfilePhoto: 'Change profile photo',
  back: 'Back',
  addDocument: 'Add document',
  hospital: 'Medical institution',
  medicalCertificate: 'Medical certificate',
  certificateInfo: 'Certificate information',
  qualification: 'Qualification',
  sendEmail: 'Send to e-mail',
  typeDocument: 'Document type',
  placeRegistration: 'Registration place',
  country: 'Country',
  strictBlank: 'Strict blank number',
  registryNumber: 'Registry number',
  speciality: 'Speciality',
  profession: 'Profession',
  specialization: 'Specialization',
  serial: 'Serial',
  comment: 'Notes',
  institution: 'Institution',
  extent: 'Extent',
  duplicate: 'Duplicate',
  yes: 'Yes',
  no: 'No',
  page: 'Page number',
  vesselInfo: 'Vessel information',
  vesselType: 'Vessel type',
  vesselName: 'Vessel name',
  vesselNumber: 'Vessel number',
  typeGEU: 'MPP type',
  powerGEU: 'MPP power',
  powerElectricity: 'Electricity power',
  dateOutput: 'Date of discharge',
  placeOutput: 'Place of discharge',
  dateInput: 'Date of embarkation',
  placeInput: 'Place of embarkation',
  responsibilityInfo: 'Responsibilities information',
  experienceInfo: 'Experience information',
  modeOfNavigation: 'Mode of navigation',
  equipmentGMZLB: 'GMDSS equipment',
  powerRefrigerating: 'Refrigeration capacity',
  practicalBook: 'Registration book of practical training',
  brokenVessel: 'Was the vessel decommissioned during the voyage',
  responsibility: 'Responsibilities',
  addDocumentOnVerification: 'Submission of the document for verification',
  changeMainPhoneNumber: 'Change the main number',
  changeEmail: 'Change email',
  doctor: 'Doctor',
  leaveAccount: 'Sign out',
  leaveAccountDescription: 'Are you sure you want to log out?',
  chooseInterfaceLanguage: 'Select the interface language',
  manageYourSessions: 'Manage your sessions',
  supportChat: 'Chat with support',
  supportChatDescription: 'Online user support',
  otherSupportContacts: 'Other options for contacting support',
  novaposhta: 'Nova poshta',
  novaposhtaCourier: 'Nova poshta (courier)',
  enterNewEmail: 'Enter the e-mail you want to register in the system',
  positionsCount: '{positions} positions',
  emailChangedTo: 'Your e-mail has been successfully changed to {email}!',
  cancelReason: 'Reason for rejection',
  cancelReasonComment: 'Comment on the reason for the rejection',
  loading: 'Loading...',
  personalDataProcessing: 'Consent to the processing of personal data',
  clientNote: 'Note to the client',
  workSchedule: 'Mon - Fri from 9:00 a.m. to 6:00 p.m',
  showAllNotifications: 'Show all notifications',
  noNotifications: 'You currently have no new notifications',
  // eslint-disable-next-line vue/max-len
  deleteNotificationsConfirmation: 'Are you sure you want to delete all notifications? Reverse operation will not be possible.',
  diplomaProof: 'Confirmation of diploma',
  chooseClarificationDataDocumentType: 'Select the type of document you want to add data to',
  chooseClarificationDataDocument: 'Select the document you want to add data to',
  uploadClarificationDataFile: 'Upload the document files for clarification',
  chooseDocumentNumber: 'Choose document №{number}',
  goToDocument: 'Go to document'
}
