export default [
  {
    path: '/',
    name: 'documents-dashboard',
    component: () => import('@/views/Documents/Dashboard'),
    meta: {
      name: 'myDocuments'
    }
  },
  {
    path: '/documents/eti',
    name: 'documents-eti-list',
    component: () => import('@/views/Documents/ETI/List'),
    meta: {
      name: 'etiCertificates'
    }
  },
  {
    path: '/documents/eti/:id',
    name: 'documents-eti-info',
    component: () => import('@/views/Documents/ETI/Info'),
    meta: {
      name: 'etiCertificates'
    }
  },
  {
    path: '/documents/verification',
    name: 'documents-verification-list',
    component: () => import('@/views/Documents/Verification/List'),
    meta: {
      name: 'documentsOnVerification'
    }
  },
  {
    path: '/documents/verification/create',
    name: 'documents-verification-create',
    component: () => import('@/views/Documents/Verification/Form/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/qualification',
    name: 'documents-qualification-list',
    component: () => import('@/views/Documents/Qualification/List'),
    meta: {
      name: 'qualificationDocuments'
    }
  },
  {
    path: '/documents/qualification/create',
    name: 'documents-qualification-create',
    component: () => import('@/views/Documents/Qualification/Form/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/qualification/:id',
    name: 'documents-qualification-info',
    component: () => import('@/views/Documents/Qualification/Info'),
    meta: {
      name: 'qualificationDocuments'
    }
  },
  {
    path: '/documents/education',
    name: 'documents-education-list',
    component: () => import('@/views/Documents/Education/List'),
    meta: {
      name: 'educationDocuments'
    }
  },
  {
    path: '/documents/education/create',
    name: 'documents-education-create',
    component: () => import('@/views/Documents/Education/Form/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/education/:id',
    name: 'documents-education-info',
    component: () => import('@/views/Documents/Education/Info'),
    meta: {
      name: 'educationDocuments'
    }
  },
  {
    path: '/documents/medical',
    name: 'documents-medical-list',
    component: () => import('@/views/Documents/Medical/List'),
    meta: {
      name: 'medicalDocuments'
    }
  },
  {
    path: '/documents/medical/create',
    name: 'documents-medical-create',
    component: () => import('@/views/Documents/Medical/Form/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/medical/:id',
    name: 'documents-medical-info',
    component: () => import('@/views/Documents/Medical/Info'),
    meta: {
      name: 'medicalDocuments'
    }
  },
  {
    path: '/documents/experience',
    name: 'documents-experience-list',
    component: () => import('@/views/Documents/Experience/List'),
    meta: {
      name: 'experienceDocuments'
    }
  },
  {
    path: '/documents/experience/service-record/create',
    name: 'documents-service-record-create',
    component: () => import('@/views/Documents/Experience/ServiceRecord/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/experience/service-record/:id',
    name: 'documents-service-record-info',
    component: () => import('@/views/Documents/Experience/ServiceRecord/Info'),
    meta: {
      name: 'sailorBook'
    }
  },
  {
    path: '/documents/experience/service-record/:serviceRecordId/entry/create',
    name: 'documents-service-record-entry-create',
    component: () => import('@/views/Documents/Experience/Entry/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/experience/service-record/:serviceRecordId/entry/:id',
    name: 'documents-service-record-entry-info',
    component: () => import('@/views/Documents/Experience/Entry/Info'),
    meta: {
      name: 'entryInServiceRecord'
    }
  },
  {
    path: '/documents/experience/entry/create',
    name: 'documents-entry-create',
    component: () => import('@/views/Documents/Experience/Entry/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/experience/entry/:id',
    name: 'documents-entry-info',
    component: () => import('@/views/Documents/Experience/Entry/Info'),
    meta: {
      name: 'entryExperience'
    }
  },
  {
    path: '/documents/experience/employment-book/create',
    name: 'documents-employment-book-create',
    component: () => import('@/views/Documents/Experience/EmploymentBook/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/protocol-sqc',
    name: 'documents-protocol-sqc-list',
    component: () => import('@/views/Documents/ProtocolSQC/List'),
    meta: {
      name: 'protocolsSQC'
    }
  },
  {
    path: '/documents/protocol-sqc/:id',
    name: 'documents-protocol-sqc-info',
    component: () => import('@/views/Documents/ProtocolSQC/Info'),
    meta: {
      name: 'protocolsSQC'
    }
  },
  {
    path: '/documents/sailor-passport/create',
    name: 'documents-sailor-passport-create',
    component: () => import('@/views/Documents/SailorPassport/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/sailor-passport/:id',
    name: 'documents-sailor-passport-info',
    component: () => import('@/views/Documents/SailorPassport/Info'),
    meta: {
      name: 'pom'
    }
  },
  {
    path: '/documents/foreign-passport/create',
    name: 'documents-foreign-passport-create',
    component: () => import('@/views/Documents/ForeignPassport/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/foreign-passport/:id',
    name: 'documents-foreign-passport-info',
    component: () => import('@/views/Documents/ForeignPassport/Info'),
    meta: {
      name: 'foreignPassport'
    }
  },
  {
    path: '/documents/civil-passport/create',
    name: 'documents-civil-passport-create',
    component: () => import('@/views/Documents/CivilPassport/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/civil-passport/:id',
    name: 'documents-civil-passport-info',
    component: () => import('@/views/Documents/CivilPassport/Info'),
    meta: {
      name: 'civilPassport'
    }
  },
  {
    path: '/documents/diploma-proof/create',
    name: 'documents-diploma-proof-create',
    component: () => import('@/views/Documents/Qualification/DiplomaProof/Form'),
    meta: {
      name: 'addDocumentOnVerification'
    }
  },
  {
    path: '/documents/diploma-proof/:id',
    name: 'documents-diploma-proof-info',
    component: () => import('@/views/Documents/Qualification/DiplomaProof/Info'),
    meta: {
      name: 'diplomaProof'
    }
  }
]
