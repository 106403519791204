const apiVersion = 'api/v1'
const apiVersion2 = 'api/v2'

export const directories = [
	{ endpoint: `${ apiVersion }/directory/region/`, state: 'region'},
	{ endpoint: `${ apiVersion }/directory/port/`, state: 'port'},
	{ endpoint: `${ apiVersion }/directory/branch_office/`, state: 'branchOffice'},
	{ endpoint: `${ apiVersion }/directory/rank/`, state: 'rank'},
	{ endpoint: `${ apiVersion }/directory/position/`, state: 'position'},
	{ endpoint: `${ apiVersion }/directory/direction/`, state: 'direction'},
	{ endpoint: `${ apiVersion }/directory/type_rank/`, state: 'typeRank'},
	{ endpoint: `${ apiVersion }/directory/limitation/`, state: 'limitation'},
	{ endpoint: `${ apiVersion }/directory/level_qualification/`, state: 'levelQualification'},
	{ endpoint: `${ apiVersion }/directory/functions_for_position/`, state: 'functionsForPosition'},
	{ endpoint: `${ apiVersion }/directory/decision/`, state: 'decision'},
	{ endpoint: `${ apiVersion }/directory/type_document/`, state: 'typeDocument'},
	{ endpoint: `${ apiVersion }/directory/status_document/`, state: 'statusDocument'},
	{ endpoint: `${ apiVersion }/directory/type_document_educational/`, state: 'typeDocumentEducation'},
	{ endpoint: `${ apiVersion }/directory/reason/`, state: 'reason'},
	{ endpoint: `${ apiVersion }/directory/eti/`, state: 'eti'},
	{ endpoint: `${ apiVersion }/directory/eti_course/`, state: 'etiCourse'},
	{ endpoint: `${ apiVersion }/directory/medical_institution/`, state: 'medicalInstitution'},
	{ endpoint: `${ apiVersion }/directory/doctor_in_medical/`, state: 'doctorInMedical'},
	{ endpoint: `${ apiVersion }/directory/position_for_medical/`, state: 'positionForMedical'},
	{ endpoint: `${ apiVersion }/directory/limitation_for_medical/`, state: 'limitationForMedical'},
	{ endpoint: `${ apiVersion }/directory/educational_institution/`, state: 'educationalInstitution'},
	{ endpoint: `${ apiVersion }/directory/speciality/`, state: 'speciality'},
	{ endpoint: `${ apiVersion }/directory/specialization/`, state: 'specialization'},
	{ endpoint: `${ apiVersion }/directory/extent_education/`, state: 'extentEducation'},
	{ endpoint: `${ apiVersion }/directory/education_form/`, state: 'educationForm'},
	{ endpoint: `${ apiVersion }/directory/faculty/`, state: 'faculty'},
	{ endpoint: `${ apiVersion }/directory/position_for_experience/`, state: 'positionForExperience'},
	{ endpoint: `${ apiVersion }/directory/type_vessel/`, state: 'typeVessel'},
	{ endpoint: `${ apiVersion }/directory/mode_of_navigation/`, state: 'modeOfNavigation'},
	{ endpoint: `${ apiVersion }/directory/type_geu/`, state: 'typeGeu'},
	{ endpoint: `${ apiVersion }/directory/responsibility_work_book/`, state: 'responsibilityWorkBook'},
	{ endpoint: `${ apiVersion }/directory/responsibility/`, state: 'responsibility'},
	{ endpoint: `${ apiVersion }/directory/morrichservice_office/`, state: 'morrichserviceOffice'},
	{ endpoint: `${ apiVersion }/directory/type_status_document/`, state: 'typeStatusDocument'},
	{ endpoint: `${ apiVersion2 }/personal_cabinet/statement_sailor_passport/choice/`, state: 'sailorPassportChoice' }
]

export const singleDirectories = {
  country: `${ apiVersion }/directory/country/`,
  region: `${ apiVersion }/directory/region/`,
  city: `${ apiVersion }/directory/city/`,
  sex: `${ apiVersion }/directory/sex/`
}

export const delivery = {
  city: `${ apiVersion }/delivery/novaposhta_city/`,
  warehouse: id => `${ apiVersion }/delivery/novaposhta_warehouse/${ id }/`,
  street: id => `${ apiVersion }/delivery/novaposhta_street/${ id }/`
}

export const auth = {
  sendVerificationCode: `${ apiVersion }/sms_auth/login/`,
  registerNewAccount: `${ apiVersion }/sms_auth/registration/`
}

export const documents = {
  education: id => `${ apiVersion2 }/personal_cabinet/education/${ id ? (id + '/') : '' }`,
  allExperience: `${ apiVersion2 }/personal_cabinet/all_experience/`,
  experience: id => `${ apiVersion2 }/personal_cabinet/experience_document/${ id ? (id + '/') : '' }`,
  availableRecordBooks: `${ apiVersion2 }/personal_cabinet/service_record/available_for_lines/`,
  citizenPassport: id =>`${ apiVersion2 }/personal_cabinet/citizen_passport/${ id ? (id + '/') : '' }`,
  foreignPassport: id => `${ apiVersion2 }/personal_cabinet/foreign_passport/${ id ? (id + '/') : '' }`,
  medical: id => `${ apiVersion2 }/personal_cabinet/medical/${ id ? (id + '/') : '' }`,
  protocolSQC: id => `${ apiVersion2 }/personal_cabinet/protocol_sqc/${ id ? (id + '/') : '' }`,
  validProtocolSQC: `${ apiVersion2 }/personal_cabinet/protocol_sqc/for_statement_qualification/`,
  resultsProtocolSQC: id => `${ apiVersion2 }/personal_cabinet/protocol_sqc/${id}/send_exam_result_to_email/`,
  sailorPassport: id => `${ apiVersion2 }/personal_cabinet/sailor_passport/${ id ? (id + '/') : '' }`,
  serviceRecord: id => `${ apiVersion2 }/personal_cabinet/service_record/${ id ? (id + '/') : '' }`,
  serviceRecordLine: (serviceBook, id) => `${ apiVersion2 }/personal_cabinet/service_record/${serviceBook}/lines/${ id }/`,
  studentId: `${ apiVersion2 }/personal_cabinet/students_id/`,
  certificateETI: id => `${ apiVersion2 }/personal_cabinet/certificate_eti/${ id ? (id + '/') : '' }`,
  etiSendEmail: id => `${ apiVersion2 }/personal_cabinet/certificate_eti/${ id }/send_to_email/`,
  etiDownload: id => `${ apiVersion2 }/personal_cabinet/certificate_eti/${ id }/download_with_watermark/`,
  qualification: id => `${ apiVersion2 }/personal_cabinet/qualification_document/${ id ? (id + '/') : '' }`,
  qualificationSendEmail: id => `${ apiVersion2 }/personal_cabinet/qualification_document/${ id }/send_to_email/`,
  qualificationDownload: id => `${ apiVersion2 }/personal_cabinet/qualification_document/${ id }/download_with_watermark/`,
  diplomasForApply: `${ apiVersion2 }/personal_cabinet/qualification_document/get_diplomas_for_apply/`,
  proof: id => `${ apiVersion2 }/personal_cabinet/proof_of_work_diploma/${ id ? (id + '/') : '' }`,
  proofSendEmail: id => `${ apiVersion2 }/personal_cabinet/proof_of_work_diploma/${ id }/send_to_email/`,
  proofDownload: id => `${ apiVersion2 }/personal_cabinet/proof_of_work_diploma/${ id }/download_with_watermark/`,
  verification: `${ apiVersion2 }/personal_cabinet/documents_on_verification/`
}

export const profile = {
  mainInfo: `${ apiVersion2 }/personal_cabinet/main_info/`,
  sailorPhoto: `${ apiVersion2 }/personal_cabinet/sailor_photo/`,
  changePhone: `${ apiVersion2 }/personal_cabinet/change_main_phone/`,
  emailVerification: `${ apiVersion2 }/personal_cabinet/email_verification/`,
  notification: id => `${ apiVersion }/notifications/${ id ? (id + '/') : '' }`,
  deleteAllNotification: `${ apiVersion }/notifications/delete_notifications/itcs/`,
  readAllNotification: `${ apiVersion }/notifications/mark_notifications_as_read/itcs/`,
  activeSessions: id => `${ apiVersion2 }/personal_cabinet/session/${ id ? (id + '/') : '' }`,
  leaveAllActiveSessions: `${ apiVersion2 }/personal_cabinet/session/exit_all_sessions/`,
  counter: `${ apiVersion2 }/personal_cabinet/document_counter/`,
  logout: `${ apiVersion2 }/personal_cabinet/logout/`
}

export const statement = {
  checkDocuments: `${ apiVersion2 }/personal_cabinet/check_documents_for_statement/`,
  unpaid: `${ apiVersion2 }/personal_cabinet/unpaid_statements/`,
  sqc: id => `${ apiVersion2 }/personal_cabinet/statement_sqc/${ id ? (id + '/') : '' }`,
  sqcCancel: id => `${ apiVersion2 }/personal_cabinet/statement_sqc/${ id }/cancel_document/`,
  sqcAllowedTypes: `${ apiVersion2 }/personal_cabinet/statement_sqc/allowed_sqc_types/`,
  sqcExtension: `${ apiVersion2 }/personal_cabinet/statement_sqc/is_continue/`,
  sqcUploadFiles: id => `${ apiVersion2 }/personal_cabinet/statement_sqc/${ id }/upload_file/`,
  sqcPayment: id => `${ apiVersion }/payments/platon/statement_sqc/${ id }/`,
  serviceRecord: id => `${ apiVersion2 }/personal_cabinet/statement_service_record/${ id ? (id + '/') : '' }`,
  serviceRecordCancel: id => `${ apiVersion2 }/personal_cabinet/statement_service_record/${ id }/cancel_document/`,
  serviceRecordUploadFiles: id => `${ apiVersion2 }/personal_cabinet/statement_service_record/${ id }/upload_file/`,
  serviceRecordPayment: id => `${ apiVersion }/payments/platon/statement_service_record/${ id }/`,
  qualification: id => `${ apiVersion2 }/personal_cabinet/statement_qualification/${ id ? (id + '/') : '' }`,
  qualificationCancel: id => `${ apiVersion2 }/personal_cabinet/statement_qualification/${ id }/cancel_document/`,
  qualificationUploadFiles: id => `${ apiVersion2 }/personal_cabinet/statement_qualification/${ id }/upload_file/`,
  qualificationCheckReplacement: `${ apiVersion2 }/personal_cabinet/statement_qualification/check_replace_document/`,
  sailorPassport: id => `${ apiVersion2 }/personal_cabinet/statement_sailor_passport/${ id ? (id + '/') : '' }`,
  sailorPassportCancel: id => `${ apiVersion2 }/personal_cabinet/statement_sailor_passport/${ id }/cancel_document/`,
  sailorPassportUploadFiles: id => `${ apiVersion2 }/personal_cabinet/statement_sailor_passport/${ id }/upload_file/`,
  sailorPassportAllowedContinue: `${ apiVersion2 }/personal_cabinet/sailor_passport/allowed_to_continue/`,
  existing: `${ apiVersion2 }/personal_cabinet/existing_document/`,
  qualificationReplace: id => `${ apiVersion2 }/personal_cabinet/statement_replace_qualification/${ id ? (id + '/') : '' }`,
  // eslint-disable-next-line vue/max-len
  qualificationReplaceCreate: id => `${ apiVersion2 }/personal_cabinet/qualification_document/${ id }/create_statement_replace/`,
  verification: `${ apiVersion2 }/personal_cabinet/statement_verification/`,
  verificationUploadFile: id => `${ apiVersion2 }/personal_cabinet/statement_verification/${ id }/upload_file/`,
  clarificationData: id => `${ apiVersion2 }/personal_cabinet/statement_clarification/${ id ? (id + '/') : '' }`,
  clarificationDataAvailableDocuments: `${ apiVersion2 }/personal_cabinet/statement_clarification/available_documents/`,
  clarificationDataUploadFiles: id => `${ apiVersion2 }/personal_cabinet/statement_clarification/${ id }/upload_file/`
}
