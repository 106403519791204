export default {
  $vuetify: {
    badge: 'Значок',
    close: 'Закрити',
    dataIterator: {
      noResultsText: 'Відповідних записів не знайдено',
      loadingText: 'Завантаження елементів...'
    },
    dataTable: {
      itemsPerPageText: 'Рядки на сторінці:',
      ariaLabel: {
        sortDescending: 'Відсортовано за спаданням.',
        sortAscending: 'Відсортовано за зростанням.',
        sortNone: 'Не відсортовані.',
        activateNone: 'Активуйте, щоб прибрати сортування.',
        activateDescending: 'Активуйте, щоб сортувати за спаданням.',
        activateAscending: 'Активуйте, щоб сортувати за зростанням.'
      },
      sortBy: 'Сортувати за'
    },
    dataFooter: {
      itemsPerPageText: 'Елементів на сторінці:',
      itemsPerPageAll: 'Усі',
      nextPage: 'Наступна сторінка',
      prevPage: 'Попередня сторінка',
      firstPage: 'Перша сторінка',
      lastPage: 'Остання сторінка',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} вибрано',
      nextMonthAriaLabel: 'Наступний місяць',
      nextYearAriaLabel: 'Наступний рік',
      prevMonthAriaLabel: 'Попередній місяць',
      prevYearAriaLabel: 'Попередній рік',
      dateStart: 'Дата початку',
      dateEnd: 'Дата кінця'
    },
    noDataText: 'Дані відсутні',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} більше'
    },
    fileInput: {
      counter: '{0} файли',
      counterSize: '{0} файлів ({1} загалом)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Навігація пагінацією',
        next: 'Наступна сторінка',
        previous: 'Попередня сторінка',
        page: 'Перейти на сторінку {0}',
        currentPage: 'Поточна сторінка, Сторінка {0}'
      }
    }
  },
  notify: {
    validation: {
      required: 'Обов\'язкове поле',
      email: 'Невірний формат email',
      maxLength: 'Перевищено максимальну довжину',
      minLength: 'Перевищено мінімальну довжину',
      maxValue: 'Перевищено максимальне значення',
      minValue: 'Перевищено мінімальне значення',
      alphaUA: 'Використовуйте тільки українську',
      alphaEN: 'Використовуйте тільки латинські літери',
      checked: 'Виконання умови є обов’язковим'
    },
    success: 'Виконано успішно',
    warning: 'Зверніть увагу',
    error: 'Помилка виконання',
    info: 'Повідомлення',
    noData: 'Дані відсутні',
    invalidUploadedFilesAmount: 'Максимальна кількість файлів: {amount}',
    invalidUploadedFilesType: 'Неможливо завантажити цей тип файлу',
    statementCreatedSuccessfully: 'Заява була створена',
    statementCanceledSuccessfully: 'Заява була скасована',
    statementPaidSuccessfully: 'Заява була сплачена',
    emailChangedSuccessfully: 'Електронна адреса змінена',
    phoneChangedSuccessfully: 'Номер телефона змінений',
    uploadedProfilePhotoSuccessfully: 'Фото профілю відправлено на верифікацію',
    documentCreatedSuccessfully: 'Документ був відправлений на верифікацію',
    documentDownloadedSuccessfully: 'Документ був завантажений на пристрій',
    sendExamResultsSuccessfully: 'Результати іспиту були відправлені на вашу пошту',
    notFoundDocuments: 'Документів не було знайдено',
    errors: {
      'Proof is not found': 'Для успішного створення документу не вистачає підтвердження диплому!',
      // eslint-disable-next-line vue/max-len
      'No valid education documents has been found': 'Для успішного створення документу не вистачає дійсного документа про освіту!',
      'Sailor does not have foreign passport': 'Для успішного створення документу не вистачає закордонного паспорту!',
      // eslint-disable-next-line vue/max-len
      'Sailor does not have document with citizenship': 'Для успішного створення документу не вистачає громадянського паспорту!',
      'Photo for document does not exists': 'Для успішного створення документу не вистачає верифікованого фото моряка!',
      'Statement replacement already exists': 'Заява на заміну кваліфікаційного документу вже існує',
      'Statement cannot be created': 'Сервіс тимчасово не працює',
      'Statement with this rank/positions exists': 'Заява за цим званням/посадою вже існує',
      'Statement exist': 'Заява вже існує',
      'Statement exists': 'Заява вже існує',
      'Document exists': 'Документ вже існує',
      'User already registered': 'Користувач вже зареєстрований',
      'User not found': 'Обліковий запис не знайдено',
      'Try later': 'Спробуйте пізніше',
      'Email is used': 'Електронна адреса вже використовується',
      'Code is invalid': 'Код недійсний',
      'Sailor does not have enough confirmed experience': 'Недостатньо підтвердженого стажу',
      'Qualification document with this statement exists': 'Заява вже використовується'
    }
  },
  btn: {
    pay: 'Сплатити',
    reject: 'Відхилити',
    cancel: 'Скасувати',
    leave: 'Вийти',
    back: 'Попередній крок',
    continue: 'Продовжити',
    register: 'Зареєструватись',
    goAuth: 'Перейти до авторизації',
    upload: 'Завантажити',
    later: 'Пізніше',
    understand: 'Зрозуміло',
    goHome: 'На головну',
    clear: 'Очистити',
    delete: 'Видалити',
    deleteAll: 'Видалити всі',
    read: 'Прочитати',
    readAll: 'Прочитати всі'
  },
  registration: 'Реєстрація',
  authorization: 'Авторизація',
  enterAuthPhoneNumber: 'Щоб виконати вхід в систему введіть<br/> свій номер телефону',
  sendVerificationCode: 'Ми відправили на ваш номер {phone} СМС повідомлення з кодом для верифікації',
  authWithPhoneNumber: 'Увійти за номером телефону',
  authWithBankID: 'Увійти через Bank ID',
  noAccount: 'Не маєте акаунту?',
  existAccount: 'Уже маєте акаунт?',
  privacyPolicyInfo: {
    iConsent: 'Я надаю',
    processingOfPersonalData: 'Згоду на обробку персональних даних',
    confirmFamiliarWithContent: 'та підтверджую, що ознайомлений(-а) зі змістом',
    processingOfPersonalNotice: 'Політики конфіденційності.'
  },
  experienceDocs: {
    'ПКМ': 'ПКМ',
    'Довідка про стаж плавання': 'Довідка про стаж плавання',
    // eslint-disable-next-line vue/max-len
    'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо': 'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо',
    'Запис ПКМ': 'Запис ПКМ'
  },
  enterSystem: 'Увійти в систему',
  enterPhoneNumber: 'Введіть номер телефону',
  enterCode: 'Введіть отриманий код',
  notReceivedCode: 'Не отримали код?',
  resendCode: 'Надіслати повторно',
  enterRequiredData: 'Внесіть обов’язкову інформацію',
  enterPassportData: 'Введіть дані з вашого паспорту',
  enterTaxNumberData: 'Введіть дані вашого ІПН',
  lastName: 'Прізвище',
  firstName: 'Ім’я',
  middleName: 'По батькові',
  lastNameUkr: 'Прізвище (українською)',
  firstNameUkr: 'Ім’я (українською)',
  middleNameUkr: 'По батькові (українською)',
  lastNameEng: 'Прізвище (англійською)',
  firstNameEng: 'Ім’я (англійською)',
  dateIssue: 'Дата видачі',
  dateTermination: 'Дата припинення дії',
  dateStudyTermination: 'Дата припинення навчання',
  dateStudyStart: 'Дата початку навчання',
  dateRenewal: 'Строк дії посвідчення продовжено до',
  dateCreate: 'Дата створення',
  email: 'E-mail',
  phoneNumber: 'Номер телефону',
  serialPassport: 'Серія та номер паспорта',
  id: 'ID',
  oldPattern: 'Старого зразка',
  idPassport: 'ID - паспорт',
  taxNumber: 'Індивідуальний Податковий Номер',
  taxNumberAvailability: 'Наявність ІПН',
  uploadPhoto: 'Завантажте фото',
  uploadPassportPhotos: 'Завантажте необхідні сторінки паспорта',
  uploadIdPassportPhotos: 'Завантажте дві фотографії ID - паспорта — з лицевої та тильної сторони',
  uploadAdditionalPhotos: 'Завантажити додаткові фото',
  uploadTaxNumberPhoto: 'Завантажте фото вашого ІНП',
  dataInProcess: 'Ваші дані в обробці',
  verificationResults: 'Результати верифікації будуть надані вам в sms-повідомленні одразу після обробки оператором.',
  support: 'Служба підтримки',
  supportDescription: 'Зв’яжіться з нашою службою підтримки зручним способом',
  explanation: 'Пояснення',
  // eslint-disable-next-line vue/max-len
  uploadPhotoExplanation: 'Завантажте фото першої, другої та третьої, четвертої та п’ятої, шостої та сьомої сторінок паспорта.</br></br>Також додайте фото з останнім записом реєстрації місця проживання',
  sailorProfile: 'Профіль моряка',
  sailorId: 'ID моряка',
  dateBirth: 'Дата народження',
  foreignPassport: 'Закордонний паспорт',
  absentForeignPassport: 'Закордонний паспорт відсутній',
  issuedBy: 'Ким видано',
  issuedByCaptain: 'Ким видано (Капітан)',
  sailorPassport: 'Посвідчення особи моряка',
  absentSailorPassport: 'Посвідчення моряка відсутнє',
  detailed: 'Детальніше',
  etiCertificates: 'НТЗ Сертифікати',
  documentsOnVerification: 'Документи на верифікації',
  medicalDocuments: 'Медичні документи',
  educationDocuments: 'Освітні документи',
  qualificationDocuments: 'Кваліфікаційні документи',
  experienceDocuments: 'Відомості про стаж',
  protocolsSQC: 'Протоколи ДКК',
  pom: 'ПОМ',
  certificate: 'Сертифікат',
  back: 'Назад',
  addDocument: 'Додати документ',
  settings: 'Налаштування',
  interfaceLanguage: 'Мова інтерфейсу',
  interfaceLanguageDescription: 'Виберіть зручну для вас мову інтерфейсу',
  // eslint-disable-next-line vue/max-len
  profilePageDescription: 'В профілі моряка відображається ваша особиста інформація.',
  generalInformation: 'Основна інформація',
  additionalInformation: 'Додаткова інформація',
  workInformation: 'Робоча інформація',
  fullNameEng: 'ПІБ (англійською)',
  sex: 'Стать',
  citizenship: 'Громадянство',
  passportDateIssue: 'Дата видачі паспорту',
  passportSerial: 'Серія та номер паспорту',
  issuedAuthority: 'Орган, що видав',
  noImage: 'Зображення відсутнє',
  noPhoto: 'Фото відсутнє',
  rank: 'Звання',
  position: 'Посада',
  editProfile: 'Редагувати профіль',
  services: 'Послуги',
  paymentRequiredStatements: 'Заяви, які потребують оплати',
  paymentRequiredStatementsDescription: 'Створені заяви, які потребують оплати',
  sqcStatement: 'Заява ДКК',
  sqcStatementDescription: 'Електронна подача заявки для підтвердження кваліфікації в Державній кваліфікаційній комісії.',
  sqcStatements: 'Заяви ДКК',
  sqcStatementCreate: 'Створення заяви ДКК',
  addExistDocument: 'Додати існуючий документ',
  addExistDocumentDescription: 'Електронна подача заявки на додавання документа в кабінет моряка.',
  qualificationStatement: 'Заява ДПВ',
  qualificationStatements: 'Заяви ДПВ',
  qualificationStatementDescription: 'Електронна подача заявки у дипломно-паспортний відділ моряка.',
  qualificationStatementCreate: 'Створення заяви ДПВ',
  foreignPassportVerificationStatements: 'Заява на верифікацію закордонного паспорта',
  foreignPassportVerificationStatementsDescription: 'Електронна подача заявки на верифікацію закордонного паспорту моряка.',
  serviceRecordBookStatement: 'Заява ПКМ',
  serviceRecordBookStatementDescription: 'Електронна подача заявки на отримання послужної книжки моряка.',
  serviceRecordBookStatements: 'Заяви ПКМ',
  serviceRecordBookStatementCreate: 'Створення заяви ПКМ',
  serviceRecordBookStatementCreateDescription: 'Оберіть місце та спосіб доставки вашої послужної книжки',
  civilPassportVerificationStatement: 'Заява на підтвердження паспорта громадянина України',
  civilPassportVerificationStatementDescription: 'Електронна подача заявки на верифікацію паспорта громадянина України.',
  sailorPassportStatement: 'Заява ПОМ',
  // eslint-disable-next-line vue/max-len
  sailorPassportStatementDescription: 'Електронна подача заявки на отримання або продовження строку дії посвідчення особи моряка.',
  sailorPassportStatements: 'Заяви ПОМ',
  sailorPassportStatementCreate: 'Створення заяви ПОМ',
  sailorPassportStatementCreateDescription: 'Електронна подача заявки на отримання або подовження строку дії ПОМ.',
  receiveSailorPassport: 'Отримати ПОМ',
  continueSailorPassport: 'Подовжити строк дії ПОМ',
  chooseSailorPassport: 'Обрати Посвідчення №{number}',
  chooseSailorPassportDescription: 'Оберіть ПОМ, строк дії якого ви бажаєте подовжити',
  clarificationDataStatement: 'Заява на уточнення даних',
  clarificationDataStatements: 'Заяви на уточнення даних',
  clarificationDataStatementCreate: 'Подача заяви на уточнення даних',
  qualificationDocumentReplaceStatements: 'Заяви на заміну робочого документу',
  qualificationDocumentReplaceStatement: 'Заява на заміну робочого документу',
  createStatement: 'Створити заяву',
  myStatements: 'Мої заяви',
  myDocuments: 'Мої документи',
  paid: 'Оплачено',
  unpaid: 'Не оплачено',
  sailorBook: 'ПКМ',
  protocol: 'Протокол',
  verificationStatements: 'Заяви на верифікацію',
  etiCertificate: 'Сертифікат НТЗ',
  eti: 'НТЗ',
  course: 'Курс',
  status: 'Статус',
  sign: 'Підпис',
  withSign: 'Підписано',
  withoutSign: 'Не підписано',
  certificateInfo: 'Інформація про сертифікат',
  downloadCertificate: 'Скачать сертифікат',
  sendEmail: 'Надіслати на e-mail',
  statementPayment: 'Оплата заяв',
  availableRequirePaymentApplications: 'У вас є заяви, які потребують оплати',
  menu: 'Меню',
  changeMainPhoneNumber: 'Змінити основний номер',
  changeEmail: 'Змінити електронну пошту',
  statementInformation: 'Інформація про заяву',
  payment: 'Оплата',
  blankPayment: 'Оплата бланка',
  examinationDate: 'Дата проведення іспиту',
  addReceipt: 'Додати квитанцію',
  cancelStatement: 'Скасувати заяву',
  cancelStatementConfirmation: 'Ви впевнені, що бажаєте скасувати заяву №{number}? Зворотня операція буде неможлива.',
  port: 'Порт',
  choosePeriod: 'Виберіть період',
  receiptPeriod: 'Період отримання',
  pleaseFillForm: 'Будь ласка, заповніть форму',
  checkInformation: 'Перевірте вашу інформацію, для подачі заяви',
  protocolSQC: 'Протокол ДКК',
  protocolInfo: 'Інформація про протокол',
  dateMeeting: 'Дата проведення засідання',
  positions: 'Посади',
  decision: 'Рішення',
  diploma: 'Диплом',
  diplomaInfo: 'Інформація про диплом',
  statementForChange: 'Заява на заміну',
  limitations: 'Обмеження',
  proofInfo: 'Інформація про підтвердження диплому',
  downloadProtocol: 'Завантажити відомість',
  educationDocument: 'Освітній документ',
  educationDocumentInfo: 'Інформація про освітній документ',
  dateExpired: 'Дата закінчення',
  serial: 'Серія',
  registryNumber: 'Реєстраційний номер',
  speciality: 'Спеціальність',
  specialization: 'Спеціалізація',
  extent: 'Рівень освіти',
  duplicate: 'Дублікат',
  yes: 'Так',
  no: 'Ні',
  medicalCertificate: 'Медичний сертифікат',
  serviceRecordInfo: 'Інформація про ПКМ',
  processingType: 'Тип оформлення',
  deliveryType: 'Спосіб доставки',
  branchOffice: 'Філія',
  additionalFee: 'За надання зазначеної послуги може стягуватись додаткова плата',
  deliveryCity: 'Місто доставки',
  department: 'Відділення',
  street: 'Вулиця',
  house: 'Будинок',
  flat: 'Квартира',
  statementType: 'Тип заяви',
  yourMissingDocuments: 'У вас недостатньо необхідних документів',
  noMissingDocuments: 'У вас є всі документи в наявності',
  yourAvailableDocuments: 'Документи, які є в наявності',
  name: 'Назва',
  number: 'Номер',
  regulatoryRequirements: 'Нормативні вимоги',
  notability: 'Відомість',
  verified: 'Верифіковано',
  notVerified: 'Не верифіковано',
  notEnoughExperience: 'Стажу недостатньо',
  experience: 'Стаж',
  notAllowedExperienceExam: 'Наразі неможливо створити заявку з типом "ДКК стаж"',
  qualificationReplaceStatements: 'Заяви на заміну робочого документа',
  qualificationReplaceStatement: 'Заява на заміну робочого документа',
  entryExperience: 'Довідка про стаж',
  entryExperienceInfo: 'Інформація про довідку про стаж',
  typeDocument: 'Тип документа',
  vesselInfo: 'Відомості про судно',
  vesselNumber: 'Номер судна',
  vesselName: 'Назва судна',
  vesselType: 'Тип судна',
  modeOfNavigation: 'Режим судноплавства',
  typeGEU: 'Тип ГЕУ',
  powerGEU: 'Потужність ГЕУ',
  powerElectricity: 'Потужність електрообладнання',
  powerRefrigerating: 'Холодопродуктивність',
  refrigeratingPlantsCount: 'Кількість холодопродуктивних установок',
  grossCapacity: 'Валова місткість',
  equipmentGMZLB: 'Апаратура ГМЗЛБ',
  refrigeratingPlantCount: 'Кількість холожопродуктивних установок',
  repair: 'Технічне обслуговування та ремонт',
  units: {
    kvt: 'кВт'
  },
  masterInfo: 'Відосомті про капітана',
  fullNameUA: 'ПІБ (українською)',
  fullNameEN: 'ПІБ (англійською)',
  experienceInfo: 'Відомості про стаж',
  practicalBook: 'Книга реєстрації практичної підготовки',
  placeInput: 'Місце працевлаштування',
  dateInput: 'Дата працевлаштування',
  placeOutput: 'Місце звільнення',
  dateOutput: 'Дата звільнення',
  brokenVessel: 'Чи було судно виведене з експлуатації під час рейсу',
  responsibilityInfo: 'Відомості про обов’язки',
  responsibility: 'Обов’язки',
  entryInServiceRecord: 'Записи у ПКМ',
  page: 'Номер сторінки',
  dateWrite: 'Дата запису',
  addDocumentOnVerification: 'Подача документу на верифікацію',
  country: 'Країна',
  strictBlank: 'Номер бланка суворої звітності',
  hospital: 'Медичний заклад',
  doctor: 'Доктор',
  institution: 'Навчальний заклад',
  qualification: 'Кваліфікація',
  yearEnd: 'Рік випуску',
  comment: 'Примітки',
  profession: 'Професія',
  index: 'Індекс',
  region: 'Область',
  city: 'Населений пункт',
  registrationPlace: 'Місце реєстрації',
  residentPlaceMatch: 'Місце проживання збігається з місцем реєстрації',
  residentPlaceNotMatch: 'Місце проживання не збігається з місцем реєстрації',
  residentPlaceData: 'Дані про місце проживання',
  residentPlace: 'Місце проживання',
  portIsNotListed: 'Порт відсутній у списку',
  fullNumberDocument: 'Повний номер документу',
  numberDocumentTillSymbol: 'Номер документа (до першого символу «/»)',
  agentFullName: 'Уповноважена особа - ПІБ (українською)',
  agentLastNameName: 'Уповноважена особа - прізвище (українською)',
  agentFirstNameName: 'Уповноважена особа - ім’я (українською)',
  agentMiddleNameName: 'Уповноважена особа - по батькові (українською)',
  agentFullNameEng: 'Уповноважена особа - ПІБ (англійською)',
  agentLastNameEng: 'Уповноважена особа - прізвище (англійською)',
  agentFirstNameEng: 'Уповноважена особа - ім’я (англійською)',
  shipOwner: 'Судновласник',
  swimmingArea: 'Район плавання',
  registrationPort: 'Порт реєстрації',
  swimmingPort: 'Морський порт',
  captainFullName: 'Капітан - ПІБ (українською)',
  captainLastName: 'Капітан - прізвище (українською)',
  captainFirstName: 'Капітан - ім’я (українською)',
  captainMiddleName: 'Капітан - по батькові (українською)',
  captainFullNameEng: 'Капітан - ПІБ (англійською)',
  captainLastNameEng: 'Капітан - прізвище (англійською)',
  captainFirstNameEng: 'Капітан - ім’я (англійською)',
  shipPosition: 'Посада на судні',
  period: 'Період',
  totalDays: 'Загальна кількість діб',
  addResponsibility: 'Додати обов’язок',
  captainInformation: 'Відомості про капітана',
  periodFrom: 'Період з',
  periodTo: 'Період по',
  workPlace: 'Місце роботи/проходження практики',
  confirmEmail: 'Підтвердіть свій e-mail',
  pleaseVerifyEmail: 'Будь ласка, підтвердіть свій e-mail, який був вказаний при реєстрації, або вкажіть новий.',
  emailVerificationCode: 'На ваш e-mail, {email} відправлено повідомлення з кодом для верифікації',
  acceptedForConsideration: 'До розгляду допущений',
  servicePaymentReceipt: 'Квитанція про сплату послуги',
  civilPassport: 'Паспорт громадянина',
  absentCivilPassport: 'Паспорт громадянина відсутній',
  passport: 'Паспорт',
  placeRegistration: 'Прописка',
  isResidenceSameRegistrationPlace: 'Прописка',
  documentInformation: 'Інформація про документ',
  additionalPhoneNumber: 'Додатковий номер',
  photoOnVerification: 'Фото знаходиться на верифікації',
  enough: 'Вистачає',
  notEnough: 'Не вистачає',
  withQualificationReplacement: 'Із заміною робочих документів',
  serviceBookEntry: 'Запис до послужної книжки',
  sendDocumentToEmail: 'Документ був відправлений на верифіковану пошту',
  attention: 'Увага',
  qualificationReplacementConfirmation: 'Ви впевнені, що хочете подати заяву на заміну документу Диплом №{number}',
  authError: 'Помилка авторизації',
  notVerifiedRegistrationStatement: 'Вашу заявку на реєстрацію ще не підтверджено!',
  addPassport: 'Додати паспорт',
  createPassportStatement: 'Створіть заявку на створення паспорта.',
  addPhoto: 'Додати фото',
  aspectRatioProfilePhoto: 'Фото повинно бути близьким до формата 3х4',
  notifications: 'Сповіщення',
  avatarRequiresFaceSize: 'Ваше обличчя повинно бути зображено крупним планом, з обома відкритими очима.',
  avatarRequiresFocus: 'Обличчя повинно перебувати у фокусі, з нейтральним виразом, без повороту чи нахилу голови та шиї.',
  avatarRequiresFaceLength: 'Довжина голови повинна складати 50% від загальної довжини фотографії.',
  openChat: 'Відкрити чат',
  documentNumber: 'Номер документу',
  link: 'Посилання',
  documentCreatedFrom: 'Документ з якого створено',
  createdDocument: 'Створений документ',
  replacementPossibility: 'З можливістю заміни',
  oops: 'Упс! Трапилась несподіванка!',
  pageNotExist: 'Вибачте, такої сторінки не існує, або була допущена помилка при вводі адреси сайту.',
  document: 'Документ',
  requiredRecordBookPage: 'До запису у ПКМ обов’язково додайте титульну сторінку ПКМ',
  activeSessions: 'Активні сеанси',
  manageYourActiveSessions: 'Керуйте вашими сеансами в системі',
  terminateAllSessions: 'Закрити всі, крім поточного',
  ipAddress: 'IP адреса: {address}',
  currentSession: 'Поточний сеанс',
  activeSession: 'Активний сеанс',
  finishedSession: 'Завершений сеанс',
  finishSession: 'Завершити сеанс',
  online: 'У мережі',
  activeSessionsNumber: '{number} активних сеансів',
  sessionsNumber: '{number} сеансів',
  uah: 'грн.',
  toBePaid: 'До сплати',
  noStatements: 'Наразі у вас немає заяв.',
  noDocuments: 'Наразі у вас відсутні документи.',
  changeProfilePhoto: 'Змінити фото профілю',
  leaveAccount: 'Вихід із системи',
  leaveAccountDescription: 'Ви впевнені, що хочете здійснити вихід із системи?',
  chooseInterfaceLanguage: 'Виберіть мову інтерфейсу',
  manageYourSessions: 'Керуйте своїми сеансами',
  supportChat: 'Чат з підтримкою',
  supportChatDescription: 'Онлайн підтримка користувача',
  otherSupportContacts: 'Інші варіанти зв’язку з підтримкою',
  novaposhta: 'Нова Пошта',
  novaposhtaCourier: 'Нова Пошта (кур\'єр)',
  enterNewEmail: 'Введіть e-mail, який хочете зареєструвати в системі',
  positionsCount: '{positions} позицій',
  emailChangedTo: 'Ваш e-mail успішно змінений на {email}!',
  cancelReason: 'Причина відхилення',
  cancelReasonComment: 'Коментар до причини відхилення',
  loading: 'Завантаження...',
  personalDataProcessing: 'Згода на обробку персональних даних',
  clientNote: 'Пам’ятка клієнту',
  workSchedule: 'Пн - Пт з 9:00 до 18:00',
  showAllNotifications: 'Дивитися всі сповіщення',
  noNotifications: 'Наразі у вас немає нових сповіщень',
  deleteNotificationsConfirmation: 'Ви впевнені, що бажаєте видалити всі сповіщення? Зворотня операція буде неможлива.',
  diplomaProof: 'Підтвердження диплому',
  chooseClarificationDataDocumentType: 'Оберіть тип документу, до якого ви бажаєте додати дані',
  chooseClarificationDataDocument: 'Оберіть документ, до якого ви бажаєте додати дані',
  uploadClarificationDataFile: 'Завантажте файли документа для уточнення',
  chooseDocumentNumber: 'Обрати документ №{number}',
  goToDocument: 'Перейти до документу'
}
