export default [
  {
    path: '/statements',
    name: 'statements',
    component: () => import('@/views/Statements/Statements.vue'),
    meta: {
      name: 'myStatements'
    }
  },
  {
    path: '/statements/unpaid',
    name: 'unpaid-statements',
    component: () => import('@/views/Statements/UnpaidStatements.vue'),
    meta: {
      name: 'paymentRequiredStatements'
    }
  },
  {
    path: '/statements/sqc',
    name: 'sqc-statements',
    component: () => import('@/views/Statements/SQC/List.vue'),
    meta: {
      name: 'sqcStatements'
    }
  },
  {
    path: '/statements/sqc/form',
    name: 'sqc-statements-form',
    component: () => import('@/views/Statements/SQC/Form.vue'),
    meta: {
      name: 'sqcStatementCreate'
    }
  },
  {
    path: '/statements/sqc/:id',
    name: 'sqc-statements-info',
    component: () => import('@/views/Statements/SQC/Info.vue'),
    meta: {
      name: 'sqcStatement'
    }
  },
  {
    path: '/statements/service-record',
    name: 'service-record-statements',
    component: () => import('@/views/Statements/ServiceRecord/List.vue'),
    meta: {
      name: 'serviceRecordBookStatements'
    }
  },
  // {
  //   path: '/statements/service-record/form',
  //   name: 'service-record-statements-form',
  //   component: () => import('@/views/Statements/ServiceRecord/Form.vue'),
  //   meta: {
  //     name: 'serviceRecordBookStatementCreate'
  //   }
  // },
  {
    path: '/statements/service-record/:id',
    name: 'service-record-statements-info',
    component: () => import('@/views/Statements/ServiceRecord/Info.vue'),
    meta: {
      name: 'serviceRecordBookStatement'
    }
  },
  {
    path: '/statements/sailor-passport',
    name: 'sailor-passport-statements',
    component: () => import('@/views/Statements/SailorPassport/List.vue'),
    meta: {
      name: 'sailorPassportStatements'
    }
  },
  {
    path: '/statements/sailor-passport/form',
    name: 'sailor-passport-statements-form',
    component: () => import('@/views/Statements/SailorPassport/Form.vue'),
    meta: {
      name: 'sailorPassportStatementCreate'
    }
  },
  {
    path: '/statements/sailor-passport/:id',
    name: 'sailor-passport-statements-info',
    component: () => import('@/views/Statements/SailorPassport/Info.vue'),
    meta: {
      name: 'sailorPassportStatement'
    }
  },
  {
    path: '/statements/verification',
    name: 'existing-documents-list',
    component: () => import('@/views/Statements/Verification/List.vue'),
    meta: {
      name: 'verificationStatements'
    }
  },
  {
    path: '/statements/qualification',
    name: 'qualification-statements',
    component: () => import('@/views/Statements/Qualification/List.vue'),
    meta: {
      name: 'qualificationStatements'
    }
  },
  {
    path: '/statements/qualification/form',
    name: 'qualification-statements-form',
    component: () => import('@/views/Statements/Qualification/Form.vue'),
    meta: {
      name: 'qualificationStatementCreate'
    }
  },
  {
    path: '/statements/qualification/:id',
    name: 'qualification-statements-info',
    component: () => import('@/views/Statements/Qualification/Info.vue'),
    meta: {
      name: 'qualificationStatement'
    }
  },
  {
    path: '/statements/qualification-replace',
    name: 'qualification-replace-statements',
    component: () => import('@/views/Statements/QualificationReplacement/List.vue'),
    meta: {
      name: 'qualificationDocumentReplaceStatements'
    }
  },
  {
    path: '/statements/qualification-replace/:id',
    name: 'qualification-replace-statements-info',
    component: () => import('@/views/Statements/QualificationReplacement/Info.vue'),
    meta: {
      name: 'qualificationDocumentReplaceStatement'
    }
  },
  {
    path: '/statements/clarification-data',
    name: 'clarification-data-statements',
    component: () => import('@/views/Statements/ClarificationData/List.vue'),
    meta: {
      name: 'clarificationDataStatements'
    }
  },
  {
    path: '/statements/clarification-data/form',
    name: 'clarification-data-statements-form',
    component: () => import('@/views/Statements/ClarificationData/Form.vue'),
    meta: {
      name: 'clarificationDataStatementCreate'
    }
  },
  {
    path: '/statements/clarification-data/:id',
    name: 'clarification-data-statements-info',
    component: () => import('@/views/Statements/ClarificationData/Info.vue'),
    meta: {
      name: 'clarificationDataStatement'
    }
  }
]
