import api from '@/plugins/api'
import {statement} from '@/configs/endpoints'
import {getQueryString} from '@/configs/functions'

const store = 'statement'
const successCode = [200, 201, 204]
export default {
  state: () => ({
    unpaidStatements: null,
    sqcStatements: null,
    serviceRecordStatements: null,
    sailorPassportStatements: null,
    sailorPassportAllowedContinue: null,
    existingDocumentStatements: null,
    qualificationStatements: null,
    qualificationReplaceStatement: null,
    clarificationDataStatements: null,
    clarificationDataStatementAvailableDocuments: [],
    verificationDocument: {
      type_document: null,
      parent_document_id: null,
      files: []
    },
    isLoading: false
  }),
  actions: {
    async checkStatementDocuments ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.checkDocuments, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    // UNPAID
    async getUnpaidStatements ({ commit, getters }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.unpaid + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        response.data.results.map(item => {
          // eslint-disable-next-line vue/max-len
          item.detailName = getters.directoryByContentType('typeDocumentsOnVerification', item.content_type_name)?.detailLink
        })
        commit('SET_DATA', { store, state: 'unpaidStatements', data: response.data })
      }
      return response
    },
    // SQC
    async setStatementSQC ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.sqc(), { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getStatementsSQC ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.sqc() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'sqcStatements', data: response.data })
      return response
    },
    async getStatementSQC ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.sqc(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async cancelStatementSQC ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.patch(statement.sqcCancel(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getStatementSQCExtension ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.sqcExtension, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async uploadFilesStatementSQC ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.sqcUploadFiles(data.id), { body: data.body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async createPaymentStatementSQC ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const url = statement.sqcPayment(data.id) + getQueryString(data.params)
      const response = await api.get(url, { responseType: 'text' })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        document.getElementById('app').innerHTML = response.data
        document.getElementById('pay').click()
      }
      return response
    },
    async getAllowedStatementSQCTypes (context, body) {
      return await api.post(statement.sqcAllowedTypes, { body })
    },
    // SERVICE RECORD BOOK
    async setServiceRecordStatement ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.serviceRecord(), { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getServiceRecordStatements ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.serviceRecord() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'serviceRecordStatements', data: response.data })
      }
      return response
    },
    async getServiceRecordStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.serviceRecord(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async cancelServiceRecordStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.patch(statement.serviceRecordCancel(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async uploadFilesServiceRecordStatement ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.serviceRecordUploadFiles(data.id), { body: data.body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async createPaymentServiceRecordStatement ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const url = statement.serviceRecordPayment(data.id) + getQueryString(data.params)
      const response = await api.get(url, { responseType: 'text' })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        document.getElementById('app').innerHTML = response.data
        document.getElementById('pay').click()
      }
      return response
    },
    // SAILOR PASSPORT
    async setSailorPassportStatement ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.sailorPassport(), { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getSailorPassportStatements ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.sailorPassport() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'sailorPassportStatements', data: response.data })
      }
      return response
    },
    async getSailorPassportStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.sailorPassport(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async cancelSailorPassportStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.patch(statement.sailorPassportCancel(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getSailorPassportAllowedContinue ({ commit }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.sailorPassportAllowedContinue)
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (response?.code === 200) commit('SET_DATA', { store, state: 'sailorPassportAllowedContinue', data: response.data })
      return response
    },
    async uploadFilesSailorPassportStatement ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.sailorPassportUploadFiles(data.id), { body: data.body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    // QUALIFICATION
    async setQualificationStatement ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.qualification(), { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getQualificationStatements ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.qualification() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'qualificationStatements', data: response.data })
      }
      return response
    },
    async getQualificationStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.qualification(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async cancelQualificationStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.patch(statement.qualificationCancel(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async uploadFilesQualificationStatement ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.qualificationUploadFiles(data.id), { body: data.body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getQualificationStatementReplaceNotification ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.qualificationCheckReplacement, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    // QUALIFICATION REPLACEMENT
    async setQualificationReplacementStatements ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.qualificationReplaceCreate(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getQualificationReplacementStatements ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.qualificationReplace() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'qualificationReplaceStatement', data: response.data })
      }
      return response
    },
    async getQualificationReplacementStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.qualificationReplace(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    // VERIFICATION
    async getVerificationStatements ({ commit, getters }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.existing + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        response.data.results.map(item => {
          item.detailName = item.status_document !== 37
            ? getters.directoryById('typeDocumentsOnVerification', item.type_document).detailLink
            : ''
        })
        commit('SET_DATA', { store, state: 'existingDocumentStatements', data: response.data })
      }
      return response
    },
    async applyDocumentToVerification ({ state, commit, dispatch }, data) {
      const body = {
        type_document: state.verificationDocument.type_document,
        additional_info: data
      }
      if (state.verificationDocument.parent_document_id) { // In case of service record book
        body.parent_document_id = state.verificationDocument.parent_document_id
      } else if (body.type_document === 100) { // Change employment book type document id
        body.type_document = 13
      }
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.verification, { body })
      if (successCode.includes(response?.code)) {
        await dispatch('uploadVerificationDocumentFile', response.data.id)
        commit('SET_DATA', { // Set default verificationDocument value
          store,
          state: 'verificationDocument',
          data: { type_document: null, parent_document_id: null, files: [] }
        })
      }
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async uploadVerificationDocumentFile ({ state }, id) {
      const body = new FormData()
      state.verificationDocument.files.forEach(item => {
        body.append('files', item)
      })
      return await api.post(statement.verificationUploadFile(id), { body })
    },

    // CLARIFICATION DATA
    async getClarificationDataStatements ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.clarificationData() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'clarificationDataStatements', data: response.data })
      }
      return response
    },
    async getClarificationDataStatement ({ commit }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(statement.clarificationData(id))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async setClarificationDataStatement ({ commit, dispatch }, { body, files }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.clarificationData(), { body })
      if (successCode.includes(response?.code) && files?.length) {
        await dispatch('uploadFilesClarificationDataStatement', { id: response.data.id, files })
      }
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async uploadFilesClarificationDataStatement ({ commit }, { id, files }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const body = new FormData()
      files.forEach(file => body.append('files', file))
      const response = await api.post(statement.clarificationDataUploadFiles(id), { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },

    async getClarificationDataStatementAvailableDocuments ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(statement.clarificationDataAvailableDocuments, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'clarificationDataStatementAvailableDocuments', data: response.data })
      }
      return response
    }
  },
  getters: {
    statementById: state => (type, id) => state[type].find(item => item.id === +id)
  }
}
