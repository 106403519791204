import Vue from 'vue'
import api from '@/plugins/api'
import router from '@/router'
import i18n from '@/plugins/vue-i18n'
import { auth, profile } from '@/configs/endpoints'
import { clearTokenValues, getQueryString } from '@/configs/functions'
import { logout } from '@ananasbear/auth'
import WarningIcon from '@/assets/icons/alert-triangle.svg'

const store = 'auth'
const successCode = [200, 201, 204]
export default {
  state: () => ({
    profile: null,
    profilePhotos: [],
    activeSessions: {},
    counters: {},
    notifications: {},
    newNotifications: {},
    newNotificationsList: [],
    isLoading: false
  }),
  mutations: {
    DECREMENT_HIDDEN_NOTIFICATIONS (state) {
      state.newNotifications.not_hidden_count -= 1
    },
    RESET_HIDDEN_NOTIFICATIONS (state) {
      state.newNotifications.not_hidden_count = 0
    },
  },
  actions: {
    async sendAuthVerificationCode ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(auth.sendVerificationCode, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },

    async registerNewAccount ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(auth.registerNewAccount, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },

    async changeMainPhoneNumber ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(profile.changePhone, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },

    async verifyEmail ({ commit }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(profile.emailVerification, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },

    async accountLogout ({ commit }) {
      const result = await Vue.prototype.$swal({
        title: `<img src="${WarningIcon}" width="24" height="24" alt="alert-triangle" />${i18n.t('leaveAccount')}!`,
        text: i18n.t('leaveAccountDescription'),
        confirmButtonText: i18n.t('btn.leave'),
        showCancelButton: true,
        cancelButtonText: i18n.t('btn.cancel'),
        showCloseButton: true,
        reverseButtons: true
      })
      if (!result.isConfirmed) return false

      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(profile.logout)
      commit('SET_DATA', { store, state: 'isLoading', data: false })

      if (successCode.includes(response?.code)) {
        if (window.$chatwoot) window.$chatwoot.reset()
        logout()
        clearTokenValues()
        router.push('/login')
      }
    },
    // COUNTERS
    async getCountDocuments ({ commit }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(profile.counter)
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'counters', data: response.data })
      return response
    },
    // PROFILE INFORMATION
    async getProfileInformation ({ commit }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(profile.mainInfo)
      if (successCode.includes(response?.code)) {
        if (response.data.photo) {
          // Get profile avatar blob
          const photoResponse = await api.get(`api/v2/personal_cabinet${response.data.photo}`, { responseType: 'blob' })
          if (photoResponse?.code === 200) response.data.avatar = photoResponse.data
        }
        commit('SET_DATA', { store, state: 'profile', data: response.data })
      }
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getProfilePhotos ({ commit }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(profile.sailorPhoto)
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'profilePhotos', data: response.data })
      return response
    },
    async setProfilePhotos ({ commit, dispatch }, body) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(profile.sailorPhoto, { body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) dispatch('getProfilePhotos')
      return response
    },
    // NOTIFICATIONS
    async getNewNotifications ({ commit }, params) {
      const response = await api.get(profile.notification() + getQueryString(params))
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'newNotifications', data: response.data })
        response.data.results.forEach(item => commit('ADD_DATA', { store, state: 'newNotificationsList', data: item }))
      }
      return response
    },
    async getProfileNotifications ({ commit, getters }, params) {
      const response = await api.get(profile.notification() + getQueryString(params))
      if (successCode.includes(response?.code)) {
        response.data.results.map(item => {
          // eslint-disable-next-line vue/max-len
          item.detailName = getters.directoryByContentType('typeDocumentsOnVerification', item.content_type)?.detailLink
        })
        commit('SET_DATA', { store, state: 'notifications', data: response.data })
      }
      return response
    },
    async updateProfileNotification ({ commit }, data) {
      const response = await api.patch(profile.notification(data.id), { body: data.body })
      if (successCode.includes(response?.code)) {
        commit('DECREMENT_HIDDEN_NOTIFICATIONS')
        commit('DELETE_DATA', { store, state: 'newNotificationsList', data: response.data.id })
        commit(data.state === 'newNotificationsList' ? 'UPDATE_DATA' : 'UPDATE_DATA_RESULTS',
          { store, state: data.state, data: response.data })
      }
      return response
    },
    async deleteProfileNotification ({ commit }, data) {
      const response = await api.delete(profile.notification(data.id), { responseType: 'text' })
      if (successCode.includes(response?.code)) {
        if (!data.is_hidden) commit('DECREMENT_HIDDEN_NOTIFICATIONS')
        commit('DELETE_DATA', { store, state: 'newNotificationsList', data: data.id })
        commit('DELETE_DATA_RESULTS', { store, state: 'notifications', data: data.id })
      }
      return response
    },
    async readAllNotification ({ commit }) {
      const response = await api.get(profile.readAllNotification)
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'newNotificationsList', data: [] })
        commit('RESET_HIDDEN_NOTIFICATIONS')
      }
      return response
    },
    async deleteAllNotification ({ commit }) {
      const response = await api.delete(profile.deleteAllNotification, { responseType: 'text' })
      if (successCode.includes(response?.code)) {
        commit('SET_DATA', { store, state: 'newNotificationsList', data: [] })
        commit('SET_DATA', { store, state: 'notifications', data: {} })
      }
      return response
    },
    // ACTIVE SESSIONS
    async getProfileActiveSessions ({ commit }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(profile.activeSessions() + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) commit('SET_DATA', { store, state: 'activeSessions', data: response.data })
      return response
    },
    async removeProfileActiveSession ({ commit, dispatch }, id) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.delete(profile.activeSessions(id), { responseType: 'text' })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) dispatch('getProfileActiveSessions')
      return response
    },
    async terminateAllProfileActiveSessions ({ commit, dispatch }) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(profile.leaveAllActiveSessions)
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        dispatch('getProfileActiveSessions')
        dispatch('getCountDocuments')
      }
      return response
    }
  },
  getters: {
  }
}
