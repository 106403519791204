import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import educationDocuments from '@/store/modules/actions/education.documents'
import qualificationDocuments from '@/store/modules/actions/qualification.documents'
import medicalDocuments from '@/store/modules/actions/medical.documents'
import etiDocuments from '@/store/modules/actions/eti.documents'
import sqcDocuments from '@/store/modules/actions/sqc.documents'
import experienceDocuments from '@/store/modules/actions/experience.documents'
import sailorPassportDocuments from '@/store/modules/actions/sailorPassport.documents'
import foreignPassportDocuments from '@/store/modules/actions/foreignPassport.documents'
import civilPassportDocuments from '@/store/modules/actions/civilPassport.documents'
import { getQueryString } from '@/configs/functions'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  state: () => ({
    isLoading: false,
    // PASSPORTS
    foreignPassport: [],
    foreignPassportSingleInfo: {},
    sailorPassport: [],
    sailorPassportSingleInfo: {},
    civilPassports: [],
    civilPassportSingleInfo: {},
    // ETI
    eti: [],
    etiSingleInfo: {},
    // QUALIFICATION
    qualification: [],
    qualificationSingleInfo: {},
    proof: [],
    proofsForDiplomaSingleInfo: {},
    diplomasForApply: [],
    // EDUCATION
    education: [],
    educationSingleInfo: {},
    // MEDICAL
    medical: [],
    medicalSingleInfo: {},
    // EXPERIENCE
    allExperience: [],
    experience: [],
    entrySingleInfo: {},
    serviceRecord: [],
    serviceRecordSingleInfo: {},
    availableRecordBooks: [],
    // SQC
    protocolSQC: [],
    protocolSQCSingleInfo: {},
    validProtocolSQC: [],
    // VERIFICATION
    verification: []
  }),
  actions: {
    async getVerificationDocuments ({ commit, getters }, params) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.get(endpoint.verification + getQueryString(params))
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      if (successCode.includes(response?.code)) {
        response.data.results.map(item => {
          item.detailName = getters.directoryById('typeDocumentsOnVerification', item.type_document).detailLink
        })
        commit('SET_DATA', { store, state: 'verification', data: response.data })
      }
      return response
    },
    ...qualificationDocuments,
    ...educationDocuments,
    ...medicalDocuments,
    ...etiDocuments,
    ...sqcDocuments,
    ...experienceDocuments,
    ...sailorPassportDocuments,
    ...foreignPassportDocuments,
    ...civilPassportDocuments
  },
  getters: {
    documentById: state => (type, id) => state[type].find(item => item.id === +id)
  }
}
