<template lang="pug">
  div.mb-8
    router-link(
      :key="i"
      v-for="(item, i) in items"
      :to="item.href"
      :disabled="item.disabled")
      span(:class="i+1 === items.length ? 'color-gray-800' : 'color-gray-500'").fs-16.mr-1 {{ item.text }}
      v-icon(v-if="i+1 !== items.length" small).mr-1 mdi-chevron-right
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    items: { type: Array, default: () => [] }
  }
}
</script>
