import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getForeignPassports ({ commit }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.foreignPassport())
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'foreignPassport', data: response.data.results })
    }
    return response
  },
  async getSailorForeignPassport ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.foreignPassport(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'foreignPassportSingleInfo', data: response.data })
    }
    return response
  }
}
