import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'
import i18n from '@/plugins/vue-i18n'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import authorization from './plugins/auth'
import api from './plugins/api'
import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'

import PageDocumentList from '@/components/layouts/PageDocumentList'
import PageInfo from '@/components/layouts/PageInfo'
import Breadcrumbs from '@/components/layouts/Breadcrumbs'
import Stepper from '@/components/layouts/Stepper'

Vue.component('PageDocumentList', PageDocumentList)
Vue.component('PageInfo', PageInfo)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Stepper', Stepper)

Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueSweetalert2)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY })

import utc from 'dayjs/plugin/utc'
const dayjs = require('dayjs')
dayjs.extend(utc)


import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/sass/index.sass'

Vue.config.productionTip = false

Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$auth = authorization
Vue.prototype.$valid = validate
Vue.prototype.$dayjs = dayjs

Vue.prototype.$labelName = i18n.locale === 'uk' ? 'name_ukr' : 'name_eng'
Vue.prototype.$labelValue = i18n.locale === 'uk' ? 'value' : 'value_eng'

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
