import notify from '@ananasbear/notify'
import store from '@/store'

export const convertObjectToFormData = obj => { // Convert nested object to FormData
  const formData = new FormData()
  const appendFormData = (data, root = '') => {
    if (data instanceof File) {
      formData.append(root, data)
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        appendFormData(data[i], root + '[' + i + ']')
      }
    } else if (typeof data === 'object' && data) {
      for (let key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) appendFormData(data[key], root === '' ? key : root + key)
      }
    } else if (data !== null && typeof data !== 'undefined') formData.append(root, data)
  }
  appendFormData(obj)
  return formData
}

export const fileConverting = file => {  // Convert file to URLObject to get image preview
  let URL = window.URL || window.webkitURL
  if (file) return URL.createObjectURL(file)
}

export const convertToTwoIntegerDigits = number => {
  if (number) return number.toLocaleString('en-US', { minimumIntegerDigits: 2 })
  else return ''
}

export const getQueryString = params => {
  const querySearch = new URLSearchParams()
  params && Object.keys(params).forEach(key => {
    if (params[key]) querySearch.append(key, String(params[key]))
  })
  if (!querySearch.has('page_size')) querySearch.append('page_size', '20')
  if (!querySearch.has('page')) querySearch.append('page', '1')
  return `?${querySearch}`
}

export const getDateInPeriod = period => {
  const date = new Date()
  date.setDate(date.getDate() + period)
  return date.toISOString()
}

export const downloadFile = (url, fileName = null) => { // Download blob file
  const link = document.createElement('a')
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.href = url
  if (fileName) link.download = fileName
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  document.body.removeChild(link)
}

export const getDocumentNameByContentType = item => {
  switch (item.content_type) {
    case 'medicalcertificate': return 'position'
    case 'certificateeti': return 'course'
    case 'qualificationdocument':
    case 'proofofworkdiploma': return 'rank'
    case 'education': return 'qualification'
    case 'sailorphoto':
    case 'foreignpassport': return 'document'
    default: return 'notability'
  }
}

export const errorsHandler = async response => {
  const data = await response.data.json()
  if (data.non_field_errors) notify.error(`notify.errors.${data.non_field_errors[0]}`)
  else if (data.response) notify.error(`notify.errors.${data.response}`)
  else if (data.description) notify.error(`notify.errors.${data.description}`)
  else if (data.email) notify.error(`notify.errors.${data.email[0]}`)
  else if (data.error) notify.error(`notify.errors.${data.error}`)
  else if (data) notify.error(`notify.errors.${data[0]}`)
}

export const clearTokenValues = () => {
  Object.entries(localStorage).map(key => {
    if (key[0].includes('TOKEN')) localStorage.removeItem(key[0])
  })
  document.cookie.split(';').forEach(i => {
    if (i.includes('TOKEN')) document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  })
}

export const handleSelectLanguage = lang => {
  if (localStorage.getItem('LOCALE') === lang) return false // Don't reload page if necessary locale is already set
  localStorage.setItem('LOCALE', lang)
  store.commit('SET_DATA', { store: 'auth', state: 'isLoading', data: true })
  window.location.reload()
}

