import api from '@/plugins/api'
import { documents as endpoint } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

const store = 'documents'
const successCode = [200, 201, 204]

export default {
  async getAvailableRecordBooks ({ commit }) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.availableRecordBooks)
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'availableRecordBooks', data: response.data })
    }
    return response
  },
  async getAllExperience ({ commit }, params) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.allExperience + getQueryString(params))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      response.data.results.map(item => {
        item.detailLink = `experience/${ item.content_type === 'servicerecord' ? 'service-record' : 'entry' }/`
      })
      commit('SET_DATA', { store, state: 'allExperience', data: response.data })
    }
    return response
  },
  async getServiceRecordDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.serviceRecord(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'serviceRecordSingleInfo', data: response.data })
    }
    return response
  },
  async getEntryDocumentInfo ({ commit }, id) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.experience(id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'entrySingleInfo', data: response.data })
    }
    return response
  },
  async getServiceBookEntryDocumentInfo ({ commit }, data) {
    commit('SET_DATA', { store, state: 'isLoading', data: true })
    const response = await api.get(endpoint.serviceRecordLine(data.serviceBook, data.id))
    commit('SET_DATA', { store, state: 'isLoading', data: false })
    if (successCode.includes(response?.code)) {
      commit('SET_DATA', { store, state: 'entrySingleInfo', data: response.data })
    }
    return response
  }
}
