<template lang="pug">
  div
    slot(name="breadcrumbs")
    div(@click="$router.push(routeBackName)").d-flex.align-center.mb-6.pointer.width-fc
      img(src="@/assets/icons/arrow-left.svg" width="6" height="12" alt="arrow-left").mr-2
      div.fs-16.fw-500 {{ $t('back') }}
    div.d-flex.justify-space-between.align-center.mb-6
      slot(name="title")
      slot(name="actions")

    Loader(v-if="isLoadDirectories")
    slot(v-else name="page")
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageDocumentList',
  props: {
    routeBackName: { type: String, default: '/' }
  },
  computed: {
    ...mapState({
      isLoadDirectories: state => state.directory.isLoadDirectories
    })
  }
}
</script>
